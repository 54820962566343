import React, { Component } from "react";
import globals from "../globals/globals";
export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ language: props.language });
  }
  sendEmail()
  {
    window.location = "mailto:categorie@artigianiverona.it?subject=Segnalazione problema Revisia web";
  }

  render() {
    var modifyCookie = this.props.modifyCookie;
    return (
      <footer>
        <div id="footer">
          <br />
          <div className="company-text">
            <span className="bigger">CASARTIGIANI VERONA</span>
            <br />
            Via Torricelli, 71/A - 37135 Verona (VR) - Italia
            <br />
            Partita IVA 04437540232
            <br />
            Tel.: +39 045 86 222 86 - Fax: +39 045 95 44 88
            <br />
            Email: categorie@artigianiverona.it
          </div>
          <div className="footer-credits">
            Credits:
            <span className="footer-link">
              <a
                href="https://www.readynet.it/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Ready Net srl{" "}
              </a>
            </span>
            Versione: 2.1.0

            {this.props.activeCookieBar ? (
                <button
                    style={{
                      width: "5%",
                      backgroundColor: "transparent",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      modifyCookie();
                    }}>
                  Cookie
                </button>
            ) : (
                <span style={{marginLeft:"1%"}}> </span>
            )}

            <span className="footer-link">
              <a
                  href={globals.domain + "/regolamento-revisia.html"}
                  target="_blank"
                  rel="noreferrer"
              >
                Regolamento revisia{" "}
              </a>
            </span>
            <span className="footer-link">
              <a
                  href={globals.domain +"/informativa-privacy-revisia.html"}
                  target="_blank"
                  rel="noreferrer"
              >
                Informativa privacy revisia{" "}
              </a>
            </span>

            <div>
            <button
                style={{
                  width: "15em",
                  backgroundColor: "#fff",
                  color:"#000",
                  fontSize: "16px"
                }}
                onClick={() => {
                  this.sendEmail();
                }}
            >
              Segnala un probema
            </button>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
