import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "./assets/css/main.css";

import Globals from "./globals/globals";

import Menu from "./components/Menu.js";
import TopBar from "./components/TopBar.js";
import Footer from "./components/Footer.js";
import Loading from "./components/Loading.js";
import NotFound from "./components/NotFound.js";

import Login from "./pages/Login.js";

import EditPassword from "./pages/EditPassword.js";
import RecoveryPassword from "./pages/RecoveryPassword.js";
import Overhaul from "./pages/Overhaul";
import Statistic from "./pages/Stats";
import Analysis from "./pages/AnalysisStats";
import Cookiebar from "./pages/Cookiebar";
import EditProfile from "./pages/EditProfile";

export default class App extends Component {
  constructor(props) {
    super(props);

    // Get language if stored in localStorage
    let storedLang = localStorage.getItem("language");

    if (storedLang === "" || storedLang === null || storedLang === undefined) {
      storedLang = "it";
    }

    this.state = {
      language: storedLang,
      accessToken: "",
      isAdmin: false,
      overhaul: false,
      isLogged: undefined,
      onlyMyOverhaul: false,
      allOverhauls: false,
        activeCookieBar: null,
    };
  }

  componentDidMount(props) {
    this.checkLogin();
      this.getActiveCookiebar();
  }

    getActiveCookiebar() {
        let url = Globals.baseUrl + "?lang=it&a=getPreferencesCookie";
        fetch(url, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === true) {
                    return "done";
                } else {
                    this.setState({
                        activeCookieBar: responseJson.active_crm
                    });
                }
            });
    }

  modifyCookie() {
    document.getElementById("container").style.display = "block";
  }

  checkLogin() {
    // Get access token if stored
    let accessToken = localStorage.getItem("accessToken");

    if (
      accessToken !== undefined &&
      accessToken !== null &&
      accessToken !== ""
    ) {
      // Try to contact the server to see if it's still viable
      let url =
        Globals.baseUrl +
        "?lang=" +
        this.state.language +
        "&a=is_logged&access_token=" +
        accessToken;

      fetch(url)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            // Error received, reset accessToken so you go back to login
            localStorage.setItem("accessToken", "");
            this.setState({
              accessToken: "",
              isLogged: false,
            });
          } else {
            if (responseJson.is_logged === true) {
              // User is logged
              this.setState({
                accessToken: accessToken,
                isLogged: true,
                isAdmin: responseJson.is_admin,
                overhaul: responseJson.overhaul,
              });
            } else {
              // Not logged, reset accessToken and go back to login
              localStorage.setItem("accessToken", "");
              this.setState({
                accessToken: "",
                isLogged: false,
              });
            }
          }
        })
        .catch((error) => {
          // Error received, reset accessToken so you go back to login
          localStorage.setItem("accessToken", "");
          this.setState({
            accessToken: "",
            isLogged: false,
          });
        });
    } else {
      // Go to the login page because you're certainly not logged
      localStorage.setItem("accessToken", "");
      this.setState({
        accessToken: "",
        isLogged: false,
      });
    }
  }

  saveAccessTokenAfterLogin(accessToken, isAdmin, overhaul) {
    localStorage.setItem("accessToken", accessToken);
    this.setState({
      accessToken: accessToken,
      isLogged: true,
      isAdmin: isAdmin,
      overhaul: overhaul,
    });
  }

  doLogout() {
    let accessToken = this.state.accessToken;

    let url =
      Globals.baseUrl +
      "?lang=" +
      this.state.language +
      "&a=logout&access_token=" +
      accessToken;

    fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === true) {
          console.log(responseJson.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    localStorage.setItem("accessToken", "");
    this.setState({
      accessToken: "",
      isLogged: false,
    });
  }

  render() {
    let redirect;
    if (this.state.isLogged === false) {
      redirect = <Redirect to={{ pathname: "/login" }} />;
    }

    let pages;
    if (this.state.isLogged === undefined) {
      pages = <Loading />;
    } else {
      pages = (
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Overhaul
                isAdmin={this.state.isAdmin}
                overhaul={this.state.overhaul}
                isLogged={this.state.isLogged}
                language={this.state.language}
                accessToken={this.state.accessToken}
                onlyMyOverhaul={false}
                doLogout={this.doLogout.bind(this)}
              />
            )}
          />
          <Route
            path="/login"
            render={(props) => (
              <Login
                saveAccessTokenAfterLogin={this.saveAccessTokenAfterLogin.bind(
                  this
                )}
                isLogged={this.state.isLogged}
                language={this.state.language}
              />
            )}
          />
          <Route
            path="/recovery"
            render={(props) => (
              <RecoveryPassword
                language={this.state.language}
                isLogged={this.state.isLogged}
              />
            )}
          />
          {/* <Route path="/cookiebar" render={(props) => <Cookiebar language={this.state.language} isLogged={this.state.isLogged}/>} /> */}

          <Route
            path="/revisioni"
            render={(props) => (
              <Overhaul
                isAdmin={this.state.isAdmin}
                overhaul={this.state.overhaul}
                isLogged={this.state.isLogged}
                language={this.state.language}
                accessToken={this.state.accessToken}
                onlyMyOverhaul={false}
                allOverhauls={false}
                doLogout={this.doLogout.bind(this)}
              />
            )}
          />
          <Route
            path="/lemiesegnalazioni"
            render={(props) => (
              <Overhaul
                isAdmin={this.state.isAdmin}
                overhaul={this.state.overhaul}
                isLogged={this.state.isLogged}
                language={this.state.language}
                accessToken={this.state.accessToken}
                onlyMyOverhaul={true}
                allOverhauls={false}
                doLogout={this.doLogout.bind(this)}
              />
            )}
          />
          <Route
            path="/archiviosegnalazioni"
            render={(props) => (
              <Overhaul
                isAdmin={this.state.isAdmin}
                overhaul={this.state.overhaul}
                isLogged={this.state.isLogged}
                language={this.state.language}
                accessToken={this.state.accessToken}
                onlyMyOverhaul={false}
                allOverhauls={true}
                doLogout={this.doLogout.bind(this)}
              />
            )}
          />

          <Route
            path="/statistiche"
            render={(props) => (
              <Statistic
                isAdmin={this.state.isAdmin}
                overhaul={this.state.overhaul}
                isLogged={this.state.isLogged}
                language={this.state.language}
                accessToken={this.state.accessToken}
              />
            )}
          />
            <Route
                path="/modifica-profilo"
                render={(props) => (
                    <EditProfile
                        isLogged={this.state.isLogged}
                        language={this.state.language}
                        accessToken={this.state.accessToken}
                        doLogout={this.doLogout.bind(this)}
                    />
                )}
            />
          <Route
            path="/analisi"
            render={(props) => (
              <Analysis
                isAdmin={this.state.isAdmin}
                overhaul={this.state.overhaul}
                isLogged={this.state.isLogged}
                language={this.state.language}
                accessToken={this.state.accessToken}
              />
            )}
          />

          <Route
            path="/modifica-password"
            render={(props) => (
              <EditPassword
                isLogged={this.state.isLogged}
                language={this.state.language}
                accessToken={this.state.accessToken}
              />
            )}
          />
          <Route render={() => <NotFound language={this.state.language} />} />
        </Switch>
      );
    }

    let topComponent = (
      <Menu
        doLogout={this.doLogout.bind(this)}
        language={this.state.language}
        isAdmin={this.state.isAdmin}
        overhaul={this.state.overhaul}
        isLogged={this.state.isLogged}
      />
    );
    if (this.state.isLogged === false) {
      topComponent = <TopBar />;
    }

    return (
      <Router>
        <div className="page-wrapper">
          {topComponent}
          <div className="site-content">
            {pages}
            {redirect}
          </div>
          <Footer
            modifyCookie={this.modifyCookie.bind(this)}
            language={this.state.language}
            activeCookieBar={this.state.activeCookieBar}
          />
        </div>
        <ToastContainer />
        <Cookiebar language={this.state.language} activeCookiebar={this.state.activeCookieBar}/>
      </Router>
    );
  }
}
