import React from "react";

let baseUrl = "https://www.artigianiverona.it/webservices/1.0/";
let domain ="https://www.artigianiverona.it";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  baseUrl = "https://artigianiverona.readynet.it/webservices/1.0/";
  domain = "https://artigianiverona.readynet.it";
}

const Globals = {
  baseUrl: baseUrl,
  domain: domain,
  menuVoices: {
    home: {
      it: "Home",
    },
    logout: {
      it: "Logout",
    },
  },

  titles: {
    home: {
      it: "Casartigiani Verona - Revisia - Home",
    },
    overhaul: {
      it: "Casartigiani Verona - Revisia - Revisioni",
    },
    logStats: {
      it: "Casartigiani Verona - Revisia - Log & Statistiche",
    },
    Analysis: {
      it: "Casartigiani Verona - Revisia - Analisi statistiche",
    },
    login: {
      it: "Casartigiani Verona - Revisia - Login",
    },
    editPassword: {
      it: "Casartigiani Verona - Revisia - Modifica password",
    },
    notFound: {
      it: "Casartigiani Verona - Revisia - Contenuto non trovato",
    },
  },

  confirms: {
    logout: {
      it: "Confermi il logout dall'applicazione?",
    },
  },

  errors: {
    serverCall: {
      it: "Si è verificato un errore durante la comunicazione con il server",
    },
    nonSequentialDates: {
      it: "La data di inizio è successiva alla data di fine. Riprova.",
    },
    dateMissing: {
      it: "Inserire una data ad entrambi i filtri",
    },
  },

  alerts: {
    mandatoryEmail: {
      it: "Email è un campo obbligatorio",
    },
    mandatoryPassword: {
      it: "Password è un campo obbligatorio",
    },
    mandatoryField: {
      it: "Campo obbligatorio",
    },
    passObbligatory:{
      it: "La password di conferma non coincide",
    },
    mandatoryName: {
      it: "Nome è un campo obbligatorio",
    },
    accountIsDisable:{
      it: "Il tuo account è stato disabilitato correttamente. Verrai ora reindirizzato alla pagina di login.",
    }
  },

  pages: {
    notFound: {
      it: (
        <div>
          Ops,
          <br />
          il contenuto che stai cercando non esiste
          <br />
          oppure non è mai esistito
        </div>
      ),
    },
  },

  footer: {
    vatCode: {
      it: "P.IVA",
    },
    socialCapital: {
      it: "Capitale sociale",
    },
    phone: {
      it: "Telefono",
    },
    fax: {
      it: "Fax",
    },
    email: {
      it: "Email",
    },
    legalNotes: {
      it: "Note legali",
    },
    legalNotesLink: {
      it: "",
    },
    termsOfUse: {
      it: "Condizioni d'utilizzo",
    },
    termsOfUseLink: {
      it: "",
    },
    credits: {
      it: "Credits",
    },
    creditsLink: {
      it: "",
    },
  },

  placeholders: {
    email: {
      it: "Email",
    },
    password: {
      it: "Password",
    },
    login: {
      it: "Login",
    },
  },

  labels: {
    ImagesUploaded: {
      it: "Immagini caricate",
    },
    Comments: {
      it: "Commenti",
    },
  },

  reactTableText: {
    previous: {
      it: "Precedente",
      en: "Previous",
      de: "Bisherige",
    },
    next: {
      it: "Successivo",
      en: "Next",
      de: "Nächster",
    },
    loading: {
      it: "Caricamento...",
      en: "Loading...",
      de: "Wird geladen...",
    },
    noData: {
      it: "Nessun dato trovato",
      en: "No data found",
      de: "Keine Daten gefunden",
    },
    page: {
      it: "Pagina",
      en: "Page",
      de: "Seite",
    },
    of: {
      it: "di",
      en: "of",
      de: "von",
    },
    rows: {
      it: "righe",
      en: "rows",
      de: "reihen",
    },
    pageJumpText: {
      it: "salta alla pagina",
      en: "jump to page",
      de: "zur seite springen",
    },
    rowsSelectorText: {
      it: "righe per pagina",
      en: "rows per page",
      de: "zeilen pro seite",
    },
  },
};

export default Globals;
