import React, { Component } from "react";
import logo from "./../assets/img/logo.png";
import { Link } from "react-router-dom";

import "font-awesome/css/font-awesome.min.css";

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language,
      menuState: 0,
      isLogged: props.isLogged,
      isAdmin: props.isAdmin,
      overhaul: props.overhaul,
      onlyMyOverhaul: false,
      allOverhauls: false,
    };
  }
  toggleMenu() {
    this.setState({
      menuState: 1 - this.state.menuState,
    });
  }
  componentWillReceiveProps(props) {
    this.setState({
      language: props.language,
      isLogged: props.isLogged,
      isAdmin: props.isAdmin,
      overhaul: props.overhaul,
    });
  }

  handleClickWithMenuOpened(event) {
    if (event.target.closest(".menu-sidebar") === null) {
      this.toggleMenu();
    }
  }

  render() {
    var doLogout = this.props.doLogout;

    return (
      <header>
        <div className="menu-bar">
          <div className="top-bar">
            <div
              className="icon open"
              onClick={this.toggleMenu.bind(this)}
            ></div>
            <div className="logo-wrapper">
              <Link to="/revisioni">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="language-selection-wrapper"></div>
          </div>
          <div
            className={
              this.state.menuState === 1
                ? "menu-body opened"
                : "menu-body closed"
            }
            onClick={(event) => this.handleClickWithMenuOpened(event)}
          >
            <div className="menu-sidebar">
              <div className="sidebar-content">
                <div className="sidebar-top-bar">
                  <div
                    className="icon close"
                    onClick={this.toggleMenu.bind(this, 1)}
                  ></div>
                  <div className="logo-wrapper">
                    <img src={logo} alt="logo" />
                  </div>
                </div>
                <div className="pages-links">
                  {this.state.isAdmin === true ||
                  this.state.overhaul === true ? (
                    <Link
                      to={{
                        pathname: "/revisioni",
                        state: { onlyMyOverhaul: false, allOverhauls: false },
                      }}
                      onClick={this.toggleMenu.bind(this)}
                    >
                      <div className="page">
                        <i className="fa fa-car"></i>
                        <span>Revisioni</span>
                      </div>
                    </Link>
                  ) : (
                    <div></div>
                  )}
                  {this.state.isAdmin === true ||
                  this.state.overhaul === true ? (
                    <Link
                      to={{
                        pathname: "/lemiesegnalazioni",
                        state: { onlyMyOverhaul: true, allOverhauls: false },
                      }}
                      onClick={this.toggleMenu.bind(this)}
                    >
                      <div className="page">
                        <i className="fa fa-user "></i>
                        <span>Le mie segnalazioni</span>
                      </div>
                    </Link>
                  ) : (
                    <div></div>
                  )}
                  {this.state.isAdmin === true ||
                  this.state.overhaul === true ? (
                    <Link
                      to={{
                        pathname: "/archiviosegnalazioni",
                        state: { allOverhauls: true, onlyMyOverhaul: false },
                      }}
                      onClick={this.toggleMenu.bind(this)}
                    >
                      <div className="page">
                        <i className="fa fa-archive"></i>
                        <span>Archivio segnalazioni </span>
                      </div>
                    </Link>
                  ) : (
                    <div></div>
                  )}
                  {this.state.isAdmin === true ? (
                    <Link
                      to={{ pathname: "/statistiche" }}
                      onClick={this.toggleMenu.bind(this)}
                    >
                      <div className="page">
                        <i className="fa fa-list  "></i>
                        <span>Logs</span>
                      </div>
                    </Link>
                  ) : (
                    <div></div>
                  )}

                  {/*{this.state.isAdmin === true ? (*/}
                  {/*  <Link*/}
                  {/*    to={{ pathname: "/analisi" }}*/}
                  {/*    onClick={this.toggleMenu.bind(this)}*/}
                  {/*  >*/}
                  {/*    <div className="page">*/}
                  {/*      <i className="fa fa-bar-chart "></i>*/}
                  {/*      <span>Analisi Statistiche </span>*/}
                  {/*    </div>*/}
                  {/*  </Link>*/}
                  {/*) : (*/}
                  {/*  <div></div>*/}
                  {/*)}*/}

                    <Link
                      to={{ pathname: "/modifica-profilo" }}
                      onClick={this.toggleMenu.bind(this)}
                    >
                      <div className="page">
                        <i className="fa fa-cog "></i>
                        <span>Profilo </span>
                      </div>
                    </Link>


                  <Link
                    to="/modifica-password"
                    onClick={this.toggleMenu.bind(this)}
                  >
                    <div className="page">
                      <i className="fa fa-key"></i>
                      <span>Modifica password</span>
                    </div>
                  </Link>
                  <div
                    className="page"
                    onClick={() => {
                      if (
                        window.confirm("Confermi il logout dall'applicazione?")
                      ) {
                        this.toggleMenu();
                        doLogout();
                      }
                    }}
                  >
                    <i className="fa fa-sign-out"></i>
                    <span>Esci</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Menu;
