import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component } from 'react';
import Globals from './../globals/globals.js';
import Loading from './../components/Loading.js';
import logoCameraCommercio from "./../assets/img/logo_footer.png";
import logoCasaArtigiani from "./../assets/img/logo-desktop.png";
import 'font-awesome/css/font-awesome.min.css';

import  { Redirect } from 'react-router-dom'

export default class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            showEmailRecoveryError: false,
            emailRecoveryErrorMessage: "",
            showEmailError: false,
            emailErrorMessage: "",
            password: "",
            showPasswordError: false,
            passwordPasswordMessage: "",
            language: props.language,
            loading: false,
            isLogged: props.isLogged,
            passwordRecoveryModalVisible: false,
            passwordRecoveryEmail: "",
            passwordRecoveryModalMessage: "",
            recoveryPass : props.recoveryPass,
            hidePasswordText: true,
        }

        this.toggleHidePasswordText = this.toggleHidePasswordText.bind(this);
    }

    componentDidMount(){
        document.title = Globals.titles.login[this.state.language];

        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(props) {
        this.setState({
            language: props.language,
            isLogged: props.isLogged,
        });
        document.title = Globals.titles.login[this.state.language];
    }

    toggleHidePasswordText() {
        this.setState({
            hidePasswordText: !this.state.hidePasswordText
        });
    }

    setInputErrorMessage( field, message ) {
        switch(field) {
            case 'emailRecovery':
                this.setState({
                    showEmailRecoveryError: true,
                    emailRecoveryErrorMessage: message
                });
                break;
            case 'email':
                this.setState({
                    showEmailError: true,
                    emailErrorMessage: message
                });
                break;
            case 'password':
                this.setState({
                    showPasswordError: true,
                    passwordErrorMessage: message
                });
                break;
            default:
                break;
        }
    }

    doLogin(e) {
        e.preventDefault();

        // Reset previous input error
        this.setState({
            showEmailError: false,
            emailErrorMessage: "",
            showPasswordError: false,
            passwordErrorMessage: "",
        });

        let email = this.state.email;
        let password = this.state.password;

        let fieldsAreOk = true;

        // Check email (mandatory field)
        if ( email === "" || email === null || email === undefined ) {
            this.setInputErrorMessage('email', Globals.alerts.mandatoryEmail[this.state.language]);
            fieldsAreOk = false;
        }

        // Check password (mandatory field)
        if ( password === "" || password === null || password === undefined ) {
            this.setInputErrorMessage('password', Globals.alerts.mandatoryPassword[this.state.language]);
            fieldsAreOk = false;
        }

        // If email or password are empty exit the function
        if ( fieldsAreOk === false ) {
            return;
        }

        // Set the loading state (it's an async action)
        this.setState({
            loading: true
        });

        // Try to login
        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=login';

        let formdata = new FormData();

        formdata.append("user", email);
        formdata.append("pass", password);
        formdata.append("revisioni", 1);

        fetch( url, {
            method: 'POST',
            body: formdata
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                this.setInputErrorMessage('password', responseJson.message);
                this.setState({
                    loading: false,
                });
            } else {
                // Salvo nel DB l'access token ricevuto
                localStorage.setItem("accessToken", responseJson.access_token);

                // Stop loading state
                this.setState({
                    loading: false,
                });

                // Pass access token to App.js and save the user as logged
                this.props.saveAccessTokenAfterLogin(responseJson.access_token, responseJson.is_admin, responseJson.overhaul);
            }
        })
        .catch((error) => {
            this.setInputErrorMessage('password', Globals.errors.serverCall[this.state.language]);

            // Stop loading state
            this.setState({
                loading: false,
            });
        });
    }

    startPasswordRecovery() {
        this.setState({
            loading: true,
            showEmailRecoveryError: false,
            emailRecoveryErrorMessage: "",
        });


        let fieldsAreOk = true;
        let email = this.state.passwordRecoveryEmail;

        if ( email === "" || email === null || email === undefined ) {
            this.setInputErrorMessage('emailRecovery', Globals.alerts.mandatoryEmail[this.state.language]);
            fieldsAreOk = false;
        }

        if ( fieldsAreOk === false ) {
            this.setState({
                loading: false,
            });
            return;
        }

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=passwordRecovery';

        let formdata = new FormData();

        formdata.append("email", email);
        fetch( url, {
            method: 'POST',
            body: formdata
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                this.setInputErrorMessage('emailRecovery', responseJson.message);
                this.setState({
                    loading: false,
                });
            } else {
                // Stop loading state
                this.setState({
                    loading: false,
                    passwordRecoveryModalMessage: "Abbiamo inviato un'email all'indirizzo indicato contenente il codice di verifica per reimpostare la password. Controlla la tua email!",
                });
            }

        })
        .catch((error) => {
            this.setInputErrorMessage('emailRecovery', Globals.errors.serverCall[this.state.language]);

            // Stop loading state
            this.setState({
                loading: false,
            });
        });
    }

    togglePasswordRecoveryModal() {
        let passwordRecoveryModalVisible = this.state.passwordRecoveryModalVisible;
        if (passwordRecoveryModalVisible === true) {
            passwordRecoveryModalVisible = false;
        } else {
            passwordRecoveryModalVisible = true;
        }
        if ( this.state.passwordRecoveryModalMessage !== "" ) {
            this.setState({
                recoveryPass: true,
                })
        }

        this.setState({
            passwordRecoveryModalVisible: passwordRecoveryModalVisible,
            passwordRecoveryEmail: "",
            showEmailRecoveryError: false,
            emailRecoveryErrorMessage: "",
            passwordRecoveryModalMessage: "",
        });
    }


    render() {
        if ( this.state.isLogged ) {
            return(
                <Redirect to="/revisioni" />
            );
        }
        let loading;
        if ( this.state.loading === true ) {
            loading = <Loading/>;
        }

        if ( this.state.recoveryPass === true ) {
            return(
                <Redirect to="/recovery" />
            );
        }

        let emailRecoveryErrorField;
        if ( this.state.showEmailRecoveryError === true ) {
            emailRecoveryErrorField = <div className="input-error-wrapper">{this.state.emailRecoveryErrorMessage}</div>;
        }

        let emailErrorField;
        if ( this.state.showEmailError === true ) {
            emailErrorField = <div className="input-error-wrapper">{this.state.emailErrorMessage}</div>;
        }

        let passwordErrorField;
        if ( this.state.showPasswordError === true ) {
            passwordErrorField = <div className="input-error-wrapper">{this.state.passwordErrorMessage}</div>;
        }

        let passwordRecoveryModal;
        if ( this.state.passwordRecoveryModalVisible === true ) {
            if ( this.state.passwordRecoveryModalMessage !== "" ) {
                passwordRecoveryModal = <div className="modal">
                    <div className="background"></div>
                    <div className="body small">
                        <div className="titleBar">
                            Recupero password
                            <div className="close-button">
                                <i className="fa fa-times" onClick={this.togglePasswordRecoveryModal.bind(this)}></i>
                            </div>
                        </div>
                        <div className="content">
                            <div className="message">
                                {this.state.passwordRecoveryModalMessage}
                            </div>
                        </div>
                    </div>
                </div>;
            } else {
                passwordRecoveryModal = <div className="modal">
                    <div className="background"></div>
                    <div className="body small">
                        <div className="titleBar">
                            Recupero password
                            <div className="close-button">
                                <i className="fa fa-times" onClick={this.togglePasswordRecoveryModal.bind(this)}></i>
                            </div>
                        </div>
                        <div className="content">
                            <div className="message">
                                Inserisci la tua email per ricevere una nuova password
                            </div>
                            <div className="input-wrapper">
                                <input type="text" name="email" placeholder="Email" value={this.state.passwordRecoveryEmail} onChange={(e) => this.setState({passwordRecoveryEmail: e.target.value})} />
                            </div>
                            {emailRecoveryErrorField}
                            <div className="input-wrapper">
                                <button type="button" onClick={this.startPasswordRecovery.bind(this)}><i className="fa fa-envelope"></i><span>Richiedi password</span></button>
                            </div>
                        </div>
                    </div>
                </div>;
            }
        }

        return(
            <div className="login-box">
                <div className="welcome-message"></div>
                <div className="fields-wrapper">
                    <form ref='loginForm' autoComplete="on" onSubmit={this.doLogin.bind(this)}>
                        <div className="input-wrapper">
                            <input type="text" name="username" placeholder={Globals.placeholders.email[this.state.language]} value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
                        </div>
                        {emailErrorField}
                        <div className="input-wrapper password-wrapper">
                            <input type={this.state.hidePasswordText ? "password" : "text"} name="password" placeholder={Globals.placeholders.password[this.state.language]} value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} />
                            <span onClick={this.toggleHidePasswordText} className="seePasswordButton"><i className="fa fa-eye"></i></span>
                        </div>
                        {passwordErrorField}
                        <div className="input-wrapper">
                            <button type="submit" onClick={this.doLogin.bind(this)}><i className="fa fa-sign-in"></i><span>{Globals.placeholders.login[this.state.language]}</span></button>
                        </div>
                    </form>
                    {loading}
                    {/* */}
                    <div className="password-recovery-wrapper" onClick={this.togglePasswordRecoveryModal.bind(this)} >

                        Password dimenticata?
                    </div>
                    <div className='mailto'>Per registrarsi scrivere un’email a <a href="mailto:info@artigianiverona.it" className='mailto-href'>info@artigianiverona.it</a></div>
                </div>
                {passwordRecoveryModal}
                <div id="newsspec-19854-app" className="news-app-promo">
                    <div className="news-app-promo-text">
                        {/*<div className="news-app-promo-text__tagline">Per una migliore esperienza.</div>*/}
                        {/*<br/>*/}
                        <div className="news-app-promo-text__download">Disponibile anche su App!</div>
                    </div>
                    <div className="news-app-promo__section">
                        <div className="news-app-promo-subsection">
                            <a className="news-app-promo-subsection--link news-app-promo-subsection--playstore"
                               href="https://play.google.com/store/apps/details?id=com.revisia&gl=IT"
                               target="_blank">
                                <img className="news-app-promo__play-store"
                                     src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/google_play_store.svg"
                                     width="161" height="auto" border="0">
                                </img>
                            </a>
                            <a className="news-app-promo-subsection--link news-app-promo-subsection--appstore"
                               href="https://apps.apple.com/it/app/revisia/id1608558898" target="_blank">
                                <img className="news-app-promo__app-store"
                                     src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/ios_app_store.svg"
                                     width="161" height="auto" border="0">
                                </img>
                            </a>
                        </div>
                        <div className="loghi">
                            <a className="news-app-promo-subsection--link news-app-promo-subsection--playstore"
                               href="https://www.vr.camcom.it/it"
                               target="_blank">
                                <img className="news-app-casa"
                                     src={logoCameraCommercio}
                                     width="161" height="auto" border="0">
                                </img>
                            </a>
                            <a className="news-app-promo-subsection--link news-app-promo-subsection--appstore"
                               href="https://www.artigianiverona.it/" target="_blank">
                                <img className="news-app-loghi"
                                     src={logoCasaArtigiani}
                                     width="161" height="auto" border="0">
                                </img>
                            </a>
                        </div>
                    </div>
                    <div className="news-app-promo__section">
                    </div>
                </div>
            </div>

            );
        }
    }