import React, { Component } from "react";
import Globals from "../globals/globals";
import Board from "../components/Board.js";
import { toast } from "react-toastify";
import Viewer from "react-viewer";
import moment from "moment";

export default class Comment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language,
      formData: props.formData,

      lastFormData: props.formData,
      accessToken: props.accessToken,
      value: "",
      files: [],

      arrayImage: [],

      previewImage: "",

      currentStatus: "",
    };

    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.removeTempImage = this.removeTempImage.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      language: props.language,
      accessToken: props.accessToken,
    });
  }


  componentWillUnmount() {
    document.body.classList.remove("form-page-wrapper");
  }

  componentDidMount() {
    this.getImage();
    this.loadNewStatus(this.props.formData.fields[0].value);
  }

  getImage() {
    let accessToken = this.props.accessToken;
    let language = "it";

    this.setState({
      loading: true,
    });

    let data = new FormData();
    data.append("id_overhaul", this.props.formData.fields[0].value);

    let url =
        Globals.baseUrl +
        "?lang=" +
        language +
        "&a=getAllImageFromOverhaul&access_token=" +
        accessToken;

    fetch(url, {
      method: "POST",
      body: data,
    })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            loading: false,
            arrayImage: responseJson.overhaulAttachments,
          });
        })
        .catch((error) => {
          // Communication error - handle message
          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        });
  }

  loadNewStatus(id) {

    let accessToken = this.props.accessToken;
    let language = "it";

    const data = new FormData();
    data.append("overhaul_id", id);

    let url =
        Globals.baseUrl +
        "?lang=" +
        language +
        "&a=getStatusModified&access_token=" +
        accessToken;

    fetch(url, {
      method: "POST",
      body: data,
    })
        .then((response) => response.json())
        .then((responseJson) => {
              this.setState({
                currentStatus: responseJson.status
              })
            }
        )
        .catch((error) => {
          console.log(error);
        });
  }

  confirmForm() {
    let formData = this.state.formData;
    this.props.confirmForm(formData);
  }

  closeForm() {
    this.props.closeForm();
  }

  handleOnDrop(files) {
    files.forEach((file) => {
      this.setState({ files: [...this.state.files, file] });
    });

    this.editFormData("image", this.state.files);
  }

  removeTempImage(id) {
    this.state.files.splice(id, 1);

    this.setState({
      files: [...this.state.files, this.state.files.splice(id, 1)],
    });
  }
  onClick(image) {
    this.setState({
      isVisible: !this.state.isVisible,
      previewImage: image,
    });
  }
  onClose() {
    this.setState({ isVisible: !this.state.isVisible });
  }

  render() {
    return (
      <div className="form-page-wrapper">
        <div className="background"></div>
        <div className="form-container">
          <div className="title-bar">
            {this.state.formData.title}
            {/*<div className="autore">*/}
            {/*          Creato da: {this.state.formData.author} - {moment(this.state.formData.dateOfCreation).format("DD/MM/YYYY HH:mm")}*/}
            {/*          */}
            {/*        </div>*/}
            <span className="close-icon">
              <i onClick={() => this.closeForm()} className="fa fa-times"></i>
            </span>
          </div>
          <div className="body">
            {this.state.formData.fields.map((item, key) => {
              if (item.visible === false) {
                return false;
              }

              let errorMessage = <div></div>;
              if (item.errorMessage !== "") {
                errorMessage = (
                  <div className="error-message">{item.errorMessage}</div>
                );
              }

              switch (item.type) {
                case "hidden":
                  return (
                    <div className="field-wrapper" key={key}>
                      <input
                        type="hidden"
                        name={item.name}
                        value={item.value}
                      />
                    </div>
                  );

                case "select":
                  if(this.state.currentStatus !== ""){
                     item.value.value = this.state.currentStatus
                  }

                  return (
                    <div className="wrapper-card" key={key}>
                      <div
                        className={
                          ("card-select",
                          item.value.value !== "Aperto"
                            ? (item.value.value !== "Revisionato")? (item.value.value !== "Sospeso")? "colorScaduto":"colorSospeso":"colorRevisionato"
                            : "colorAperto")
                        }
                      >
                        <div className="card-title">{item.label}</div>
                        {item.value.value}
                      </div>
                    </div>
                  );

                case "text":
                  return (
                    <div className="wrapper-card" key={key}>
                      <div className="card">
                        <div className="card-title">{item.label}</div>
                        <div className="card-content">{item.value}</div>
                      </div>
                    </div>
                  );

                case "textarea":
                  return (
                    <div className="wrapper" key={key}>
                      <div className="card-desc">
                        <div className="card-title"> Descrizione </div>
                        <div className="card-content-desc">
                          {item.value}
                          {errorMessage}
                        </div>
                      </div>
                    </div>
                  );

                case "anomalie":

                  if (item.value !== null && item.value !=="") {                    
                    for (let n = 0; n < item.value.length; n++) {
                      const lista = item.value.map((element) => (
                        <div className="lista" key={element.id}> {element.label}</div>
                      ));

                      return (
                        <div className="field-wrapper" key={key}>
                          <div className="label-anomalie">{item.label}</div>
                          <div>{lista}</div>
                        </div>
                      );
                    }
                  }
                  return "";

                case "image":
                  return (
                    <div className="field-wrapper" key={key}>
                      {this.state.arrayImage !== undefined ? (
                        <div className="uploaded-files-wrapper">
                          <div className="label-immagini">
                            {" "}
                            {
                              Globals.labels.ImagesUploaded[this.state.language]
                            }{" "}
                          </div>
                          {this.state.arrayImage.image === undefined
                            ? ""
                            : this.state.arrayImage.image.map(
                                (item, key, array) => {
                                  return (
                                    <div key={key} className="file">
                                      <div className="name">{item.name}</div>
                                      <div className="image">
                                        <img
                                          src={item.url}
                                          className="previewImage"
                                          alt=""
                                        />
                                      </div>

                                      {/* Viewer image */}
                                      <div>
                                        <button
                                          onClick={() => {
                                            this.onClick(array[key].url);
                                          }}
                                        >
                                          {" "}
                                          <i className="fa fa-eye"></i>
                                        </button>
                                        <Viewer
                                          drag={false}
                                          className={"react-viewer-icon"}
                                          noImgDetails={false}
                                          zoomSpeed={0.6}
                                          disableMouseZoom
                                          rotatable={false}
                                          scalable={false}
                                          minScale={1}
                                          changeable={false}
                                          visible={this.state.isVisible}
                                          onClose={() => {
                                            this.onClose();
                                          }}
                                          images={[
                                            {
                                              src: this.state.previewImage,
                                              alt: "Image",
                                            },
                                          ]}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="label-comments">
                        {" "}
                        {Globals.labels.Comments[this.state.language]}{" "}
                      </div>
                    </div>
                  );

                default:
                  return false;
              }
            })}
            <Board
              formData={this.props.formData}
              accessToken={this.props.accessToken}
              getList={this.props.getList}
              closeForm={this.props.closeForm}
              loadNewStatus={this.loadNewStatus.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}
