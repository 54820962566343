import React, { Component } from "react";
import Loading from "../components/Loading";
import Globals from "../globals/globals";
import { toast } from "react-toastify";

export default class Actions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language,
      accessToken: props.accessToken,
      idAction: props.idAction,
      loading: false,
      actions: null,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      language: props.language,
      accessToken: props.accessToken,
      idAction: props.idAction,
    });
  }

  componentDidMount() {
    this.getAllAction();
  }

  getAllAction() {
    let accessToken = this.state.accessToken;
    let idAction = this.state.idAction;
    let language = this.state.language;

    this.setState({
      loading: true,
    });

    let data = new FormData();

    data.append("session_id", idAction.id);

    let url =
      Globals.baseUrl +
      "?lang=" +
      language +
      "&a=getAllActionFromSession&access_token=" +
      accessToken;

    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === true) {
          // Error during the call - handle message
          toast(responseJson.message, {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        } else {
          // Call is ok
          let Data = responseJson.action_session;
          let arrayAction = [];
          if (Data !== undefined) {
            Data.forEach((element) => {
              arrayAction = arrayAction.concat([element]);
            });
          }

          this.setState({
            loading: false,
            actions: arrayAction,
          });
        }
      })
      .catch((error) => {
        // Communication error - handle message
        toast(Globals.errors.serverCall[this.state.language], {
          autoClose: 8000,
          className: "std-black-toast",
          bodyClassName: "std-black-toast-body",
          progressClassName: "std-black-toast-progress-bar",
        });

        this.setState({
          loading: false,
        });
      });
  }

  closeForm() {
    this.props.closeForm();
  }

  render() {
    if (this.state.loading === true) {
      return <Loading />;
    }

    return (
      <div className="form-page-wrapper">
        <div className="background"></div>
        <div className="form-container">
          <div className="title-bar">
            Azioni eseguite durante la sessione
            <span className="close-icon">
              <i onClick={() => this.closeForm()} className="fa fa-times"></i>
            </span>
          </div>
          <div className="body">
            <div className="actioncontainer">
              <table>
                <tr>
                  <th> DATA - ORA </th> <th> AZIONE </th>
                </tr>
                {this.state.actions !== null ? (
                  this.state.actions.map((item, index) => {
                    return (
                      <tr key={index}>
                        {" "}
                        <td> {item.data} </td>
                        <td>
                          {" "}
                          <i> </i> {item.azione}{" "}
                        </td>{" "}
                      </tr>
                    );
                  })
                ) : (
                  <span> NESSUNA AZIONE ESEGUITA </span>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
