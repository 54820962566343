import React, { Component } from "react";
import Globals from "./../globals/globals.js";
import Loading from "./../components/Loading.js";
import { toast } from "react-toastify";
import "font-awesome/css/font-awesome.min.css";

import { Redirect, Link } from "react-router-dom";

export default class EditProfile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirectToHome: false,

			loading: true,

			isLogged: props.isLogged,
			language: props.language,
			accessToken: props.accessToken,

		};
	};

	deleteProfile() {
		if (
			window.confirm(
				"Attenzione, Confermi di voler disabilitare il profilo ?"
			) === true
		) {
			this.setState({
				loading: true,
				redirectToHome: true,
			});
			let accessToken = this.state.accessToken;
			// Try to login
			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=disableProfile&access_token=" +
				accessToken;

			fetch(url, {
				method: "POST",
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						this.setState({
							loading: false,
						});

						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});


					} else {

						toast(Globals.alerts.accountIsDisable[this.state.language], {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.props.doLogout();

					}
				})
				.catch((error) => {

					this.setState({
						loading: false,
					});

					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});
				});

		}
	}

		render (){
			return(
				<div>
					<div className="edit-password-box">
						<div className="fields-wrapper">
							<div className="input-wrapper">

								<div style={{textAlign:"center"}}>
								ATTENZIONE!
									<br/>
									Una volta disattivato l'account non si avrà più accesso all'applicazione. Per la riattivazione sarà necessario contattare un amministratore.</div>
							</div>


							<div className="input-wrapper">
								<button type="button" onClick={() => this.deleteProfile()}><i className="fa fa-disable"></i><span>Disabilita account</span></button>
							</div>
							<Link to="/login">
								<div className="input-wrapper">
									<button type="button"><i className="fa fa-arrow-left"></i><span>Annulla</span></button>
								</div>
							</Link>
						</div>
					</div>
				</div>
			);
		}


}