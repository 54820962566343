import React, { Component } from "react";
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";

export default class AnalysisStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogged: props.isLogged,
      isAdmin: props.isAdmin,
      overhaul: props.overhaul,
      language: props.language,
      accessToken: props.accessToken,
    };
  }

  componentWillUnmount() {
    document.body.classList.remove("page-with-filter-bar");
  }

  componentDidMount() {
    document.body.classList.add("page-with-filter-bar");

    document.title = Globals.titles.Analysis[this.state.language];

    window.scrollTo(0, 0);

  }

  componentWillReceiveProps(props) {
    this.setState({
      isLogged: props.isLogged,
      isAdmin: props.isAdmin,
      overhaul: props.overhaul,
      language: props.language,
      accessToken: props.accessToken,
    });
    document.title = Globals.titles.Analysis[props.language];
  }


  render() {
    if (this.state.overhaul !== true && this.state.isAdmin !== true) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    return <div className="table-wrapper">
      <iframe className="iframe" src="https://statsrevisioni.readynet.it/dash/" title="Analisi e statistiche">
</iframe>
    </div>;
  }
}
