import React, { Component } from "react";
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import Loading from "../components/Loading";
import moment from "moment";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import "react-table/react-table.css";

import "react-table/react-table.css";
import Actions from "../components/Actions";

export default class Stats extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			overhaul: props.overhaul,
			language: props.language,
			accessToken: props.accessToken,
			onlyMyOverhaul: props.onlyMyOverhaul,
			userId: null,

			loading: false,

			showSessionActions: false,
			list: undefined,
			listMessage: "",
			wordFilter: null,

			idAction: null,

			startDate: null,
			endDate: null,

			comments: "",
		};

		this.handleStartDateChange = this.handleStartDateChange.bind(this);
		this.handleEndDateChange = this.handleEndDateChange.bind(this);
	}

	componentWillUnmount() {
		document.body.classList.remove("page-with-filter-bar");
	}

	componentDidMount() {
		document.body.classList.add("page-with-filter-bar");

		document.title = Globals.titles.logStats[this.state.language];

		window.scrollTo(0, 0);
		//casistica delle mie revisioni

		this.getStats();
	}

	componentWillReceiveProps(props) {
		this.setState({
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			overhaul: props.overhaul,
			language: props.language,
			accessToken: props.accessToken,
			onlyMyOverhaul: props.onlyMyOverhaul,
		});
		document.title = Globals.titles.logStats[props.language];
	}

	handleWordFilter(state, value) {
		this.setState({
			wordFilter: value,
		});
	}

	handleStartDateChange(date) {
		this.setState({
			startDate: date,
		});
	}

	handleEndDateChange(date) {
		this.setState({
			endDate: date,
		});
	}

	formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		if (year === 1970) {
			return null;
		}

		return [year, month, day].join("-");
	}

	reset() {
		this.setState({
			wordFilter: "",
			startDate: null,
			endDate: null,
		});
		this.getStats();
	}

	getStats(specialaction) {
		//Reset state
		this.setState({
			list: undefined,
			listMessage: "",
			loading: true,
		});

		let data = new FormData();

		// Fetch api for overhauls' list
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=getAllSessionStatistic&access_token=" +
			accessToken;

		if (specialaction === false) {
			data.append("wordFilter", this.state.wordFilter);

			if (
				this.formatDate(this.state.endDate) <
				this.formatDate(this.state.startDate)
			) {
				this.setState({
					list: [],
					listMessage:
						Globals.errors.nonSequentialDates[this.state.language],
				});
				return;
			}

			if (
				(!this.formatDate(this.state.endDate) &&
					this.formatDate(this.state.startDate)) ||
				(this.formatDate(this.state.endDate) &&
					!this.formatDate(this.state.startDate))
			) {
				this.setState({
					list: [],
					listMessage:
						Globals.errors.dateMissing[this.state.language],
				});
				return;
			}

			if (this.state.startDate !== null && this.state.endDate !== null) {
				data.append("startDate", this.formatDate(this.state.startDate));
				data.append("endDate", this.formatDate(this.state.endDate));
			}

			fetch(url, {
				method: "POST",
				body: data,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						this.setState({
							list: [],
							listMessage: responseJson.message,
						});

						return;
					}

					this.setState({
						list: responseJson.stats,
						listMessage: "",
						loading: false,
					});
				})
				.catch((error) => {
					// Communication error - handle message
					this.setState({
						list: [],
						listMessage:
							Globals.errors.serverCall[this.state.language],
						loading: false,
					});
				});
		} else {
			fetch(url, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						this.setState({
							list: [],
							listMessage: responseJson.message,
						});

						return;
					}

					this.setState({
						list: responseJson.stats,
						listMessage: "",
						loading: false,
					});
				})
				.catch((error) => {
					// Communication error - handle message
					this.setState({
						list: [],
						listMessage:
							Globals.errors.serverCall[this.state.language],
						loading: false,
					});
				});
		}
	}

	getActionElement(id) {
		let element = this.state.list.find((item) => {
			return parseInt(item.id) === parseInt(id);
		});

		this.setState({
			showSessionActions: true,
			idAction: element,
		});
	}

	deleteElement(id) {
		if (window.confirm("Confermi l'eliminazione?")) {
			this.setState({
				loading: true,
			});

			// Fetch api for overhauls' list
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=deleteSession&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("id", id);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					this.setState({
						loading: false,
					});

					if (responseJson.error === true) {
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						return;
					}

					toast("Operazione completata", {
						autoClose: 4000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					let actualList = cloneDeep(this.state.list);

					let newList = actualList.filter((item) => {
						return parseInt(item.id) !== parseInt(id);
					});

					this.setState({
						list: newList,
					});
				})
				.catch((error) => {
					this.setState({
						loading: false,
					});

					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});
				});
		}
	}

	closeForm() {
		this.setState({
			showSessionActions: false,
			idAction: null,
		});
	}

	render() {
		if (this.state.overhaul !== true && this.state.isAdmin !== true) {
			return <Redirect to={{ pathname: "/" }} />;
		}

		if (this.state.list === undefined) {
			return <Loading />;
		}

		if (this.state.list.length === 0 && this.state.listMessage !== "") {
			return (
				<div className='error-message'>{this.state.listMessage}</div>
			);
		}

		// List is OK, Render it
		// Prepare data for the table;
		const columns = [
			{
				Header: "Data",
				accessor: "data",
				width: 400,
				Cell: (c) => (
					<div style={{ textAlign: "center" }}>
						{" "}
						<span>
							{moment(c.original.data).format("DD/MM/YYYY H:MM")}
						</span>{" "}
					</div>
				),
			},
			{
				Header: "Azienda",
				accessor: "company",
				style: { overflow: "visible", whiteSpace: "normal"  },
				Cell: (row) => (
					<div style={{ textAlign: "center" }}>{row.value}</div>
				),
			},
			{
				Header: "Utente",
				accessor: "user",
				style: { overflow: "visible", whiteSpace: "normal"  },
				Cell: (row) => (
					<div style={{ textAlign: "center" }}>{row.value}</div>
				),
			},
			{
				Header: "APPLICAZIONE MOBILE",
				accessor: "is_mobile",
				Cell: (row) => (
					<div style={{ textAlign: "center" }}>{row.value}</div>
				),
			},
			{
				Header: "",
				accessor: "detailIcon",
				width: 150,
				Cell: (row) => (
					<div style={{ textAlign: "center" }}>{row.value}</div>
				),
			},
			{
				Header: "",
				accessor: "deleteIcon",
				width: 150,
				Cell: (row) => (
					<div style={{ textAlign: "center" }}>{row.value}</div>
				),
			},
		];
		const data = [];
		let list = this.state.list;
		for (let c = 0; c < list.length; c++) {
			data.push({
				data: list[c].data,
				company: list[c].azienda,
				user: list[c].utente,
				is_mobile:
					parseInt(list[c].is_mobile) === 1 ? (
						<i className='fa fa-check fa-2x' />
					) : (
						<i className='fa fa-times fa-2x' />
					),
				detailIcon: (
					<i
						className='std-hoverable-cursor-pointer fa fa-eye fa-2x'
						onClick={() => {
							this.getActionElement(list[c].id);
						}}></i>
				),
				deleteIcon: (
					<i
						className='std-hoverable-cursor-pointer fa fa-trash fa-2x'
						onClick={() => {
							this.deleteElement(list[c].id);
						}}></i>
				),
			});
		}

		return (
			<div className='table-wrapper'>
				<div className='filters'>
					<div className='filters-wrapper'>
						<div className='filter-wrapper'>
							<div className='filter-wrapper-date'>
								<div className='label'>Filtra per data:</div>
								<div className='filter-data-start'>
									<DatePicker
										locale={this.state.language}
										selected={this.state.startDate}
										dateFormat='dd/MM/yyyy'
										onChange={this.handleStartDateChange}
										placeholderText={"Data inizio"}
									/>
								</div>
								{/* <div className="label">"data fine"</div> */}
								<div className='filter-data-end'>
									<DatePicker
										locale={this.state.language}
										selected={this.state.endDate}
										dateFormat='dd/MM/yyyy'
										onChange={this.handleEndDateChange}
										placeholderText={"Data fine"}
									/>
								</div>
							</div>

							<div className='filter'></div>
						</div>
						{/* Start Filtri per barra di ricerca in homepage */}
						<div className='filter-wrapper'>
							<input
								type='text'
								placeholder='Cerca sessione (targa, modello ecc..)'
								value={this.state.wordFilter}
								onChange={(e) =>
									this.handleWordFilter(
										this.state.wordFilter,
										e.target.value
									)
								}
							/>
						</div>
						<div className='filter-wrapper'>
							<div className='filter'>
								<button
									type='button'
									onClick={() => this.getStats(false)}>
									<span>Filtra</span>
								</button>
								<button
									className='button-reset'
									type='button'
									onClick={() => this.reset()}>
									<span>RESET</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<ReactTable
					data={data}
					columns={columns}
					pageSizeOptions={[25, 50, 100, 200]}
					defaultPageSize={25}
					previousText={
						Globals.reactTableText.previous[this.state.language]
					}
					nextText={Globals.reactTableText.next[this.state.language]}
					loadingText={
						Globals.reactTableText.loading[this.state.language]
					}
					noDataText={
						Globals.reactTableText.noData[this.state.language]
					}
					pageText={Globals.reactTableText.page[this.state.language]}
					ofText={Globals.reactTableText.of[this.state.language]}
					rowsText={Globals.reactTableText.rows[this.state.language]}
					pageJumpText={
						Globals.reactTableText.pageJumpText[this.state.language]
					}
					rowsSelectorText={
						Globals.reactTableText.rowsSelectorText[
							this.state.language
						]
					}
				/>
				{this.state.showSessionActions === true ? (
					<Actions
						idAction={this.state.idAction}
						closeForm={this.closeForm.bind(this)}
						accessToken={this.props.accessToken}
						language={this.props.language}
					/>
				) : (
					""
				)}
			</div>
		);
	}
}
