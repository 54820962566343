import React, { Component } from "react";
import cloneDeep from "lodash/cloneDeep";
import Select from "react-select";
import Globals from "../globals/globals";
import Dropzone from "react-dropzone";
import Viewer from "react-viewer";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";

export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language,
      formData: props.formData,

      lastFormData: props.formData,
      accessToken: props.accessToken,

      arrayImage: [],
      value: "",
      files: [],
      problemList: [],

      previewImage: "",

    };

    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.removeTempImage = this.removeTempImage.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      language: props.language,
      accessToken: props.accessToken,
    });
  }

  componentDidMount() {
    if (this.state.lastFormData.title !== "Nuovo") {
      this.getImage();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("form-page-wrapper");
  }

  editFormData(field, value) {
    let newFormData = cloneDeep(this.state.formData);
    let index = newFormData.fields.findIndex((element) => {
      return element.name === field;
    });

    newFormData.fields[index].value = value;

    this.setState({
      formData: newFormData,
    });
  }
  confirmForm() {
    let formData = this.state.formData;
    this.props.confirmForm(formData);
  }

  closeForm() {
    this.props.closeForm();
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onClick(image) {
    this.setState({
      isVisible: !this.state.isVisible,
      previewImage: image,
    });
  }
  
  onClose() {
    this.setState({ isVisible: !this.state.isVisible });
  }

  handleOnDrop(files) {
    files.forEach((file) => {
      this.setState({ files: [...this.state.files, file] });
    });

    this.editFormData("image", this.state.files);
  }

  handleProblemList(problemList) {
    if (problemList.length === problemList) {
      this.setState(this.state.problemList, problemList);
    } else {
      this.setState({ ...this.state.problemList, problemList });
    }
    this.editFormData("anomalie", problemList);
  }

  removeImage(fileId) {
    if (window.confirm("Confermi l'eliminazione?")) {
      this.setState({
        loading: true,
      });

      // Fetch api
      let accessToken = this.state.accessToken;

      let url =
        Globals.baseUrl +
        "?lang=" +
        this.state.language +
        "&a=deleteOverhaulImage&access_token=" +
        accessToken;

      const formdata = new FormData();

      formdata.append("fileId", fileId);

      fetch(url, {
        method: "POST",
        body: formdata,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            // Error during the call - handle message
            toast(responseJson.message, {
              autoClose: 8000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });
          } else {
            // Call is ok
            toast("Operazione completata", {
              autoClose: 4000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            //this.toggleFilesHandler();

            this.getImage();
            this.props.getList();

            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          // Communication error - handle message
          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        });
    }
  }

  removeTempImage(id) {
    this.state.files.splice(id, 1);

    this.setState({
      files: [...this.state.files, this.state.files.splice(id, 1)],
    });
  }

  getImage() {
    let accessToken = this.props.accessToken;
    let language = "it";

    this.setState({
      loading: true,
    });

    let data = new FormData();
    data.append("id_overhaul", this.props.formData.fields[0].value);

    let url =
      Globals.baseUrl +
      "?lang=" +
      language +
      "&a=getAllImageFromOverhaul&access_token=" +
      accessToken;

    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          loading: false,
          arrayImage: responseJson.overhaulAttachments,
        });
      })
      .catch((error) => {
        // Communication error - handle message
        toast(Globals.errors.serverCall[this.state.language], {
          autoClose: 8000,
          className: "std-black-toast",
          bodyClassName: "std-black-toast-body",
          progressClassName: "std-black-toast-progress-bar",
        });

        this.setState({
          loading: false,
        });
      });
  }

  render() {
    return (
      <div className="form-page-wrapper">
        <div className="background"></div>
        <div className="form-container">
          <div className="title-bar">
            {this.state.formData.title}
            <span className="close-icon">
              <i onClick={() => this.closeForm()} className="fa fa-times"></i>
            </span>
          </div>
          <div className="body">
            {this.state.formData.fields.map((item, key) => {
              if (item.visible === false) {
                return false;
              }

              let errorMessage = <div></div>;
              if (item.errorMessage !== "") {
                errorMessage = (
                  <div className="error-message">{item.errorMessage}</div>
                );
              }

              switch (item.type) {
                case "hidden":
                  return (
                    <div className="field-wrapper" key={key}>
                      <input
                        type="hidden"
                        name={item.name}
                        value={item.value}
                      />
                    </div>
                  );

                case "select":
                  return (
                    <div className="field-wrapper" key={key}>
                      <div className="label">{item.label}</div>
                      <Select
                        value={item.value}
                        onChange={(e) => {
                          this.editFormData(item.name, e);
                        }}
                        options={item.options}
                      />
                      {errorMessage}
                    </div>
                  );

                case "text":
                  return (
                    <div className="field-wrapper" key={key}>
                      <div className="label">{item.label}</div>
                      <input
                        type="text"
                        name={item.name}
                        value={item.value}
                        onChange={(e) =>
                          this.editFormData(item.name, e.target.value)
                        }
                      />
                      {errorMessage}
                    </div>
                  );

                case "textarea":
                  return (
                    <div className="field-wrapper-textarea" key={key}>
                      <div className="label">{item.label}</div>
                      <textarea
                        name={item.name}
                        value={item.value}
                        onChange={(e) =>
                          this.editFormData(item.name, e.target.value)
                        }
                      />
                      {errorMessage}
                    </div>
                  );
                case "anomalie":
                  return (
                    <div className="field-wrapper-anomalie" key={key}>
                      <div className="label">{item.label}</div>
                      <div>
                        <pre></pre>
                        {/* <pre>{JSON.stringify(this.state.problemList)}</pre> */}
                        {/* Case multiselect */}
                        <MultiSelect
                          overrideStrings={{
                            allItemsAreSelected:
                              "Tutti gli elementi selezionati.",
                            clearSearch: "Cancella ricerca",
                            clearSelected: "Cancella Selezionato",
                            noOptions: "Nessuna opzione",
                            search: "Cerca",
                            selectAll: "Seleziona tutti",
                            selectAllFiltered: "Seleziona tutti (Filtrato)",
                            selectSomeItems: "Seleziona...",
                            create: "Crea nuovo: ",
                          }}
                          options={item.options}
                          value={item.value}
                          hasSelectAll={true}
                          onChange={(e) => {
                            this.handleProblemList(e);
                            
                          }}
                        />
                      </div>
                    </div>
                  );

                case "image":
                  return (
                    <div className="field-wrapper" key={key}>
                      {this.state.arrayImage !== undefined &&
                      this.state.lastFormData.title !== "Nuovo" ? (
                        <div className="uploaded-files-wrapper">
                          <div className="label">
                            {" "}
                            {
                              Globals.labels.ImagesUploaded[this.state.language]
                            }{" "}
                          </div>
                          {this.state.arrayImage.image === undefined
                            ? ""
                            : this.state.arrayImage.image.map((item, key, array) => {
                                return (
                                  <div key={key} className="file">
                                    <div className="name">{item.name}</div>
                                    <div className="image">
                                      <img
                                        src={item.url}
                                        className="previewImage"
                                        alt=""
                                      />
                                    </div>
                                    <div className="button-eye-left">
                                    <div className="button-left">
                                        <button
                                          onClick={() => {
                                            this.onClick(array[key].url);
                                          }}
                                        >
                                          {" "}
                                          <i className="fa fa-eye"></i>
                                        </button>
                                        <Viewer
                                          drag={false}
                                          className={"react-viewer-icon"}
                                          noImgDetails={false}
                                          zoomSpeed={0.6}
                                          disableMouseZoom
                                          rotatable={false}
                                          scalable={false}
                                          minScale={1}
                                          changeable={false}
                                          visible={this.state.isVisible}
                                          onClose={() => {
                                            this.onClose();
                                          }}
                                          images={[
                                            {
                                              src: this.state.previewImage,
                                              alt: "Image",
                                            },
                                          ]}
                                        />
                                      </div>
                                      <div className="button-right">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          this.removeImage(item.id)
                                        }
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="label">{item.label}</div>
                      <div className="dropzone-wrapper">
                        <Dropzone
                          onDrop={this.handleOnDrop.bind(this)}
                          accept="image/*"
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <section>
                              <div
                                {...getRootProps()}
                                className={
                                  "drag-and-drop-wrapper " +
                                  (isDragActive ? "active" : "")
                                }
                              >
                                <input {...getInputProps()} />
                                <p>
                                  Trascina qui le tue immagini o fai click{" "}
                                  <span>qui</span> per selezionarli
                                  <span className="small">
                                    (File accettati:.jpeg, .jpg, .png, .gif)
                                  </span>
                                </p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {this.state.files !== undefined ? (
                          <div className="uploaded-files-wrapper">
                            {this.state.files.map((item, key) => {
                              if (item.name !== undefined) {
                                return (
                                  <div key={key} className="file">
                                    <div className="name">{item.name}</div>
                                    {/* convert image */}
                                    <img
                                      className="image"
                                      src={URL.createObjectURL(item)}
                                      alt={<i className="fa file-image-o"></i>}
                                    />

                                    <div className="button">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          this.removeTempImage(key)
                                        }
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </div>
                                );
                              } else {
                                return "";
                              }
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );

                default:
                  return false;
              }
            })}
          </div>
          <div className="buttons-wrapper">
            <input
              type="button"
              name="confirm"
              value="Conferma"
              className="confirm-button"
              onClick={() => this.confirmForm()}
            />
          </div>
        </div>
      </div>
    );
  }
}
