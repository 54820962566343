import React from "react";
import moment from "moment";
import Select from "react-select";
import MultiSelect from "@khanacademy/react-multi-select";

const options = [
  { label: "Lucidatura fari", value: 1 },
        { label: "Gomme sostiuite", value: 2 },
        { label: "Airbag ripristinato", value: 3 },
        { label: "Fumi regolari", value: 4 },
        { label: "Luci stop riparate", value: 5 },
        { label: "Ammortizzatori sistemati/sostituiti", value: 6 },
        { label: "Servofreno sistemato/sostituito", value: 7 },
        { label: "Bracci anteriori sistemati/sostituiti", value: 8 },
        { label: "Bracci posteriori sistemati/sostituiti", value: 9 },
        { label: "Targhe leggibili", value: 10 },
        { label: "Vetro anteriore riparato/sostituito", value: 11 },
        { label: "Vetro posteriore sistemato/sostituito", value: 12 },
        { label: "Lunotto sistemato/sostituito", value: 13 },
        { label: "Marmitta sistemata/sostituita", value: 14 },
        { label: "Sostituite lampadine", value: 15 },
];

export default class ContainerComments extends React.Component {
  constructor(props) {
    super(props);
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.editStatus = this.editStatus.bind(this);

    this.state = {
      editing: false,
      options: [
        {
          value: "Nessuno",
          label: "Nessuna modifica",
        },
        {
          value: "Sospeso",
          label: "Sospeso",
        },
        {
          value: "Revisionato",
          label: "Revisionato",
        },
      ],
      optionsRepair: [
        { label: "Lucidatura fari", value: "1" },
        { label: "Gomme sostiuite", value: "2" },
        { label: "Airbag ripristinato", value: "3" },
        { label: "Fumi regolari", value: "4" },
        { label: "Luci stop riparate", value: "5" },
        { label: "Ammortizzatori sistemati/sostituiti", value: "6" },
        { label: "Servofreno sistemato/sostituito", value: "7" },
        { label: "Bracci anteriori sistemati/sostituiti", value: "8" },
        { label: "Bracci posteriori sistemati/sostituiti", value: "9" },
        { label: "Targhe leggibili", value: "10" },
        { label: "Vetro anteriore riparato/sostituito", value: "11" },
        { label: "Vetro posteriore sistemato/sostituito", value: "12" },
        { label: "Lunotto sistemato/sostituito", value: "13" },
        { label: "Marmitta sistemata/sostituita", value: "14" },
        { label: "Sostituite lampadine", value: "15" },
      ],
      status: "Nessuno",
      listOfRapairs: (this.props.children['repairValue'].length === 0)? [] : this.props.children['repairValue'].map(i=>Number(i.value)),
    };
  }

  edit() {
    this.setState({ editing: true });

  }

  save() {
    var status = this.state.status;
    var newText = this.newText.value;

    this.props.updateCommentFromBoard(
      status,
      newText,
      this.props.children["id"],
      this.state.listOfRapairs,
    );
    this.setState({ editing: false });
  }

  remove() {
    this.props.removeCommentFromBoard(this.props.children["id"]);
  }
  renderNormalMode() {
    let repairs = "";
    let lista = "";

    if (this.props.children["repair"] !== null) {
      repairs = this.props.children["repair"];

      for (var i = 0; i < repairs.length; i++) {
          
        lista = repairs.map((element) => (
          <div className="lista" key={element.id}> {element.label}</div>
        ));

      }
    }


    return (
      <div
        className={
          "commentContainer" +
          (parseInt(this.props.formData.userId) ===
          parseInt(this.props.children["users_id"])
            ? ""
            : "black")
        }
      >
        <div className="commentAuthor">
          {/* Status : {this.props.children["changed_status"]} */}
          {this.props.children["changed_status"] !== ""
            ? "Status: " + this.props.children["changed_status"]
            : "Status non modificato"}
        </div>
        {/*<div className="commentAuthor">*/}
        {/*   Utente :*/}
        {/*  {this.props.children["author"]}*/}
        {/*</div>*/}

        <div className="commentText">{this.props.children["text"]}</div>
        
        {((this.props.children["repairs"] !== null && lista.length > 0) || Array.isArray(lista) !== false) ? (
          
          <div className="commentTextRiparazioni">
            {/* <hr className="separator"></hr> */}
            {" "}
            <span className="intestazioneRiparazioni">Riparazioni effettuate:</span>
            {lista.map((item, index) => {
              return  <div key={index}> {index + 1} :  {item.props.children[1]}</div>

            }) }
          </div>
        ) : (
          ""
        )}

        <div className="commentDateCreation">
          {/* Data creazione : */}{" "}
          {moment(this.props.children["created_on"]).format("DD/MM/YYYY HH:mm")}
        </div>
        {parseInt(this.props.formData.userId) ===
        parseInt(this.props.children["users_id"]) ? (
          <button onClick={this.edit} className="btn btn-comment">
            <span className="fa fa-pencil"></span>
          </button>
        ) : (
          ""
        )}
        {parseInt(this.props.formData.userId) ===
        parseInt(this.props.children["users_id"]) ? (
          <button onClick={this.remove} className="btn btn-comment">
            <span className="fa fa-trash "></span>
          </button>
        ) : (
          ""
        )}
      </div>
    );
  }

  editStatus(status) {
    this.setState({
      status: status.value,
    });
  }


  renderEditingMode() {

    return (
      <div className="commentContainer">
        <div>
          <Select
            onChange={(e) => {
              this.editStatus(e);
            }}
            placeholder={this.props.children["changed_status"]}
            options={this.state.options}
          />
          <MultiSelect
                className={"multiselect-zindex"}
                styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                    options={options}
                    selected={this.state.listOfRapairs}
                    onSelectedChanged={(selected) => this.setState({listOfRapairs: selected})}
                    overrideStrings={{
                        selectSomeItems: "Seleziona uno o più riparazioni",
                        allItemsAreSelected: "Tutti gli elementi sono selezionati",
                        selectAll: "Seleziona tutti",
                        search: "Cerca",
                    }}
                  />
          <div className="shareCommentContainerModify">
          <textarea
            ref={(input) => {
              this.newText = input;
            }}
            onChange={this.handleChange}
            defaultValue={this.props.children["text"]}
          ></textarea>
          </div>


        </div>

        <button onClick={this.save} className="btn-comment">
          <span className="fa fa-floppy-o"></span>
        </button>
      </div>
    );
  }

  render() {
    if (this.state.editing) {
      return this.renderEditingMode();
    } else {
      return this.renderNormalMode();
    }
  }
}
