import React, { Component } from "react";
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import Loading from "../components/Loading";
import moment from "moment";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import Form from "../components/Form";
import Comment from "../components/Comment";

import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import "react-table/react-table.css";

import "react-table/react-table.css";
import it from "date-fns/locale/it";



registerLocale("it", it);
export default class Overhaul extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogged: props.isLogged,
      isAdmin: props.isAdmin,
      overhaul: props.overhaul,
      language: props.language,
      accessToken: props.accessToken,
      onlyMyOverhaul: props.onlyMyOverhaul,
      allOverhauls: props.allOverhauls,

      author: "",
      userId: null,

      loading: false,

      showForm: false,
      showComment: false,

      formData: {
        title: "",
        formAction: "",
        fields: [
          {
            name: "id",
            label: "ID",
            visible: false,
            value: null,
            type: "hidden",
            mandatory: false,
            errorMessage: "",
          },
          {
            name: "status",
            label: "Status",
            visible: true,
            value: { value: "Aperto", label: "Aperto" },
            type: "select",
            options: [
              {
                value: "Aperto",
                label: "Aperto",
              },
              {
                value: "Sospeso",
                label: "Sospeso",
              },
              {
                value: "Revisionato",
                label: "Revisionato",
              },
            ],
            mandatory: true,
            errorMessage: "",
          },
          {
            name: "plate",
            label: "Targa",
            visible: true,
            value: "",
            type: "text",
            mandatory: true,
            errorMessage: "",
          },
          {
            name: "title",
            label: "Modello",
            visible: true,
            value: "",
            type: "text",
            mandatory: true,
            errorMessage: "",
          },
          {
            name: "brand",
            label: "Marca",
            visible: true,
            value: "",
            type: "text",
            mandatory: true,
            errorMessage: "",
          },
          {
            name: "text",
            label: "Testo",
            visible: true,
            value: "",
            type: "textarea",
            mandatory: true,
            errorMessage: "",
          },
          {
            name: "anomalie",
            label: "Anomalie riscontrate",
            visible: true,
            value: [],
            type: "anomalie",
            options: [
              { label: "Fari opacizzati", value: "1" },
              { label: "Gomme finite", value: "2" },
              { label: "Spia airbag", value: "3" },
              { label: "Fumo nero", value: "4" },
              { label: "Mancanza di stop", value: "5" },
              { label: "Ammortizzatori finiti", value: "6" },
              { label: "Servofreno", value: "7" },
              { label: "Bracci anteriori", value: "8" },
              { label: "Bracci posteriori", value: "9" },
              { label: "Targhe non leggibili", value: "10" },
              { label: "Vetro anteriore crepato", value: "11" },
              { label: "Vetro posteriore crepato", value: "12" },
              { label: "Lunotto crepato", value: "13" },
              { label: "Marmitta usurata", value: "14" },
            ],
            mandatory: false,
          },
          {
            name: "image",
            label: "Carica Immagini (non obbligatorio)",
            visible: true,
            value: [],
            type: "image",
            mandatory: false,
            errorMessage: "",
          },
        ],
      },

      list: undefined,
      listMessage: "",
      // isFilterable: false,
      statusFilter: "",
      wordFilter: "",

      startDate: null,
      endDate: null,

      comments: "",
    };
    this.handleFilterStatus = this.handleFilterStatus.bind(this);
    this.handleWordFilter = this.handleWordFilter.bind(this);

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentWillUnmount() {
    document.body.classList.remove("page-with-filter-bar");
  }

  componentDidMount() {
    document.body.classList.add("page-with-filter-bar");
    document.title = Globals.titles.overhaul[this.state.language];

    window.scrollTo(0, 0);
    //casistica delle mie revisioni
    if (this.props.onlyMyOverhaul === true) {
      this.getList(false);
    } else if (this.props.allOverhauls === true) {
      this.getList(true);
    } else {
      this.getList();
    }
  }
  componentDidUpdate(props) {
    if (
      this.props.onlyMyOverhaul !== props.onlyMyOverhaul ||
      this.props.allOverhauls !== props.allOverhauls
    ) {
      if (
        this.props.onlyMyOverhaul === true &&
        this.props.allOverhauls === false
      ) {
        this.handleResetStatusMyOverhauls();
      } else if (
        this.props.allOverhauls === true &&
        this.props.onlyMyOverhaul === false
      ) {
        this.handleResetStatusAllOverhauls();
      } else if (
        this.props.allOverhauls === false &&
        this.props.onlyMyOverhaul === false
      ) {
        this.handleResetStatusMyOverhauls();
        this.getList();
      }
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      isLogged: props.isLogged,
      isAdmin: props.isAdmin,
      overhaul: props.overhaul,
      language: props.language,
      accessToken: props.accessToken,
      onlyMyOverhaul: props.onlyMyOverhaul,
      allOverhauls: props.allOverhauls,
    });
    document.title = Globals.titles.overhaul[props.language];
  }
  handleStartDateChange(date) {
    this.setState({
      startDate: date,
    });
  }

  handleEndDateChange(date) {
    this.setState({
      endDate: date,
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (year === 1970) {
      return null;
    }

    return [year, month, day].join("-");
  }

  handleFilterStatus(Status) {
    this.setState({ statusFilter: Status }, () => {
      this.getList(false);
    });
  }
  handleResetStatusMyOverhauls() {
    this.setState(
      { statusFilter: "", wordFilter: "", startDate: null, endDate: null },
      () => {
        this.getList(false);
      }
    );
  }
  handleResetStatusAllOverhauls() {
    this.setState(
      { statusFilter: "", wordFilter: "", startDate: null, endDate: null },
      () => {
        this.getList(true);
      }
    );
  }

  reset() {
    this.setState({
      statusFilter: "",
      wordFilter: "",
      startDate: null,
      endDate: null,
    });

    if (this.props.onlyMyOverhaul === true) {
      this.getList(false);
    } else if (this.props.allOverhauls === true) {
      this.getList(true);
    } else {
      this.getList();
    }
  }

  handleWordFilter(state, value) {
    this.setState({
      wordFilter: value,
    });
  }


  getTrProps = (state, rowInfo, instance) => {

    if (rowInfo && this.state.isAdmin === false) {

      return {
        style: {
          boxShadow:
            rowInfo.row.editIcon !== ""
              ? "1px 3px 1px  rgba(0,0,0,0.30)"
              : "white",
          marginBottom: rowInfo.row.editIcon !== "" ? "10px" : "",
          background:
            rowInfo.row.editIcon !== ""
              ? "-webkit-linear-gradient(top, #eeeeee 0%,#cccccc 100%)"
              : "",
        },
      };
    }
    // if(rowInfo && this.state.formData.fields[1].value.value !== "Scaduta"){
    //   return {
    //   };
    // }else{
    //   return {
    //     style: {
    //       boxShadow:
    //           rowInfo.row.editIcon !== ""
    //               ? "1px 3px 1px  rgba(0,0,0,0.30)"
    //               : "white",
    //       marginBottom: rowInfo.row.editIcon !== "" ? "10px" : "",
    //       background:
    //           rowInfo.row.editIcon !== ""
    //               ? "-webkit-linear-gradient(top, #eeeeee 0%,#cccccc 100%)"
    //               : "",
    //     },
    //   };
    // }
    return {};
  };

  getList(specialaction) {
    //Reset state
    this.setState({
      list: undefined,
      listMessage: "",
      loading: true,
    });

    let data = new FormData();

    // console.log(today)
    // console.log(this.state.startDate)

    // Fetch api for overhauls' list
    let accessToken = this.state.accessToken;

    let url =
      Globals.baseUrl +
      "?lang=" +
      this.state.language +
      "&a=getOverhaulsList&access_token=" +
      accessToken;
    if (specialaction === false) {
      data.append("OnlyMyOwnFiles", this.state.onlyMyOverhaul);
      data.append("Status", this.state.statusFilter);
      data.append("wordFilter", this.state.wordFilter);

      if (
        this.formatDate(this.state.endDate) <
        this.formatDate(this.state.startDate)
      ) {
        this.setState({
          list: [],
          listMessage: Globals.errors.nonSequentialDates[this.state.language],
        });
        return;
      }

      if (
        (!this.formatDate(this.state.endDate) &&
          this.formatDate(this.state.startDate)) ||
        (this.formatDate(this.state.endDate) &&
          !this.formatDate(this.state.startDate))
      ) {
        this.setState({
          list: [],
          listMessage: Globals.errors.dateMissing[this.state.language],
        });
        return;
      }

      if (this.state.startDate !== null && this.state.endDate !== null) {
        data.append("startDate", this.formatDate(this.state.startDate));
        data.append("endDate", this.formatDate(this.state.endDate));

      }

      fetch(url, {
        method: "POST",
        body: data,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            this.setState({
              list: [],
              listMessage: responseJson.message,
            });

            return;
          }

          this.setState({
            list: responseJson.overhauls,
            listMessage: "",
            userId: responseJson.user_id,
            author: responseJson.author_name,
            loading: false,
          });
        })
        .catch((error) => {
          // Communication error - handle message
          this.setState({
            list: [],
            listMessage: Globals.errors.serverCall[this.state.language],
            loading: false,
          });
        });
    } else if (specialaction === true) {
      data.append("AllFiles", this.state.allOverhauls);
      data.append("Status", this.state.statusFilter);
      data.append("wordFilter", this.state.wordFilter);

      if (
        this.formatDate(this.state.endDate) <
        this.formatDate(this.state.startDate)
      ) {
        this.setState({
          list: [],
          listMessage: Globals.errors.nonSequentialDates[this.state.language],
        });
        return;
      }

      if (
        (!this.formatDate(this.state.endDate) &&
          this.formatDate(this.state.startDate)) ||
        (this.formatDate(this.state.endDate) &&
          !this.formatDate(this.state.startDate))
      ) {
        this.setState({
          list: [],
          listMessage: Globals.errors.dateMissing[this.state.language],
        });
        return;
      }

      if (this.state.startDate !== null && this.state.endDate !== null) {
        data.append("startDate", this.formatDate(this.state.startDate));
        data.append("endDate", this.formatDate(this.state.endDate));
      }

      fetch(url, {
        method: "POST",
        body: data,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            this.setState({
              list: [],
              listMessage: responseJson.message,
            });

            return;
          }

          this.setState({
            list: responseJson.overhauls,
            listMessage: "",
            userId: responseJson.user_id,
            author: responseJson.author_name,
            loading: false,
          });
        })
        .catch((error) => {
          // Communication error - handle message
          this.setState({
            list: [],
            listMessage: Globals.errors.serverCall[this.state.language],
            loading: false,
          });
        });
    } else {
      fetch(url, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            this.setState({
              list: [],
              listMessage: responseJson.message,
            });

            return;
          }


          this.setState({
            list: responseJson.overhauls,
            listMessage: "",
            userId: responseJson.user_id,
            author: responseJson.author_name,
            loading: false,
          });
        })
        .catch((error) => {
          // Communication error - handle message
          this.setState({
            list: [],
            listMessage: Globals.errors.serverCall[this.state.language],
            loading: false,
          });
        });
    }
  }
  truncate(str) {
    if (str.length > 230) {
      str =  str.substring(0, 180) + "..."
      return str 
      }else{ 
       return str
      };
  }
  confirmForm(formData) {
    if (window.confirm("Confermi nuovo elemento?")) {
      // Controllo i campi obbligatori
      let fieldsErrors = false;

      for (let c = 0; c < formData.fields.length; c++) {
        let field = formData.fields[c];

        if (field.mandatory === true) {
          formData.fields[c].errorMessage = "";

          switch (field.type) {
            case "select":
              if (field.value.value === "") {
                formData.fields[c].errorMessage = "Campo obbligatorio";
                fieldsErrors = true;
              }
              break;

            default:
              if (field.value === "") {
                formData.fields[c].errorMessage = "Campo obbligatorio";
                fieldsErrors = true;
              }
              break;
          }
        }
      }

      // Aggiorno lo stato con i dati inseriti e gli eventuali errori
      this.setState({
        formData: formData,
      });

      if (fieldsErrors === true) {
        return;
      }

      // Start loading state
      this.setState({
        loading: true,
      });

      // Fetch api for overhauls' list
      let accessToken = this.state.accessToken;

      let url =
        Globals.baseUrl +
        "?lang=" +
        this.state.language +
        "&a=" +
        formData.formAction +
        "&access_token=" +
        accessToken;

      let postBody = new FormData();

      postBody.append("id", formData.fields[0].value);
      postBody.append("status", formData.fields[1].value.value);
      postBody.append("plate", formData.fields[2].value);
      postBody.append("title", formData.fields[3].value);
      postBody.append("brand", formData.fields[4].value);
      postBody.append("text", formData.fields[5].value);

      if (
        formData.fields[6].value !== undefined ||
        formData.fields[6].value.length !== null
      ) {
        postBody.append("anomalies", JSON.stringify(formData.fields[6].value));
      }

      if (
        formData.fields[7].value !== undefined ||
        formData.fields[7].value.length !== null
      ) {
        formData.fields[7].value.forEach((file) => {
          postBody.append("uploadedFiles[]", file);
        });
      }

      fetch(url, {
        method: "POST",
        body: postBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            loading: false,
          });

          if (responseJson.error === true) {
            toast(responseJson.message, {
              autoClose: 8000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            return;
          }

          toast("Operazione completata", {
            autoClose: 4000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.closeForm();

          if (this.props.onlyMyOverhaul === true) {
            this.getList(false);
          } else if (this.props.allOverhauls === true) {
            this.getList(true);
          } else {
            this.getList();
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });
        });
    }
  }

  closeForm() {
    this.setState((prevState) => ({
      showForm: false,
      showComment: false,

      formData: {
        title: "",
        formAction: "",
        fields: [
          {
            ...prevState.formData.fields[0],
            value: null,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[1],
            value: { value: "Aperto", label: "Aperto" },
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[2],
            value: "",
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[3],
            value: "",
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[4],
            value: "",
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[5],
            value: "",
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[6],
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[7],
            value: "",
            errorMessage: "",
          },
        ],
      },
    }));
  }
  newElement() {
    // Show form
    this.setState((prevState) => ({
      showForm: true,

      formData: {
        title: "Nuovo",
        formAction: "newOverhaul",
        fields: [
          {
            ...prevState.formData.fields[0],
            value: null,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[1],
            value: { value: "Aperto", label: "Aperto" },
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[2],
            value: "",
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[3],
            value: "",
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[4],
            value: "",
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[5],
            value: "",
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[6],
            value: [],
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[7],
            value: [],
            errorMessage: "",
          },
        ],
      },
    }));
  }
  editElement(id) {
    let element = this.state.list.find((item) => {
      return parseInt(item.id) === parseInt(id);
    });

    // Show form
    this.setState((prevState) => ({
      showForm: true,

      formData: {
        title: "Modifica",
        formAction: "editOverhaul",
        fields: [
          {
            ...prevState.formData.fields[0],
            value: element.id,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[1],
            value: { value: element.status, label: element.status },
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[2],
            value: element.plate,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[3],
            value: element.title,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[4],
            value: element.brand,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[5],
            value: element.text,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[6],
            value: element.anomalies,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[7],
            value: [],
            errorMessage: "",
          },
        ],
      },
    }));
  }
  commentElement(id) {
    let element = this.state.list.find((item) => {
      return parseInt(item.id) === parseInt(id);
    });
    let visibleBrand = true;
    if(element.brand === ""){
      visibleBrand = false;
    }
    // Show form
    this.setState((prevState) => ({
      showComment: true,

      formData: {
        title: "Commenta",
        formAction: "commentOverhaul",
        userId: this.state.userId,
        author: element.author,
        dateOfCreation: element.created_on,

        fields: [
          {
            ...prevState.formData.fields[0],
            value: element.id,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[1],
            value: { value: element.status, label: element.status },
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[2],
            value: element.plate,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[3],
            value: element.title,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[4],
            value: element.brand,
            errorMessage: "",
            visible: visibleBrand,
          },
          {
            ...prevState.formData.fields[5],
            value: element.text,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[6],
            value: element.anomalies,
            errorMessage: "",
          },
          {
            ...prevState.formData.fields[7],
            value: "",
            errorMessage: "",
          },
        ],
        comments: element.comments,
      },
    }));
  }
  deleteElement(id) {
    if (window.confirm("Confermi l'eliminazione?")) {
      this.setState({
        loading: true,
      });

      // Fetch api for overhauls' list
      let accessToken = this.state.accessToken;

      let url =
        Globals.baseUrl +
        "?lang=" +
        this.state.language +
        "&a=deleteOverhaul&access_token=" +
        accessToken;

      let formdata = new FormData();

      formdata.append("id", id);

      fetch(url, {
        method: "POST",
        body: formdata,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            loading: false,
          });

          if (responseJson.error === true) {
            toast(responseJson.message, {
              autoClose: 8000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            return;
          }

          toast("Operazione completata", {
            autoClose: 4000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          let actualList = cloneDeep(this.state.list);

          let newList = actualList.filter((item) => {
            return parseInt(item.id) !== parseInt(id);
          });

          this.setState({
            list: newList,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });
        });
    }
  }

  render() {
    if (this.state.overhaul !== true && this.state.isAdmin !== true) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (this.state.list === undefined) {
      return <Loading />;
    }

    if (this.state.list.length === 0 && this.state.listMessage !== "") {
      return (
        <div className="table-wrapper">
          {/* Logged utente */}
          <div className="logged">
            Accesso effetuato come:
            <div className="author">{this.state.author}</div>
          </div>
          <div className="filters">
            <div className="filters-wrapper">
              <div className="filter-wrapper">
                <div className="filter">
                  <button type="button" onClick={() => this.newElement()}>
                    <span>
                      {" "}
                      <i className="std-hoverable-cursor-pointer fa fa-plus"></i>{" "}
                      AGGIUNGI{" "}
                    </span>
                  </button>
                </div>
              </div>
              {/* Start Filtri per barra di ricerca in homepage */}
              <div className="filter-wrapper">
                <div className="filter-status">
                  <p className="title-status-filter"> Filtra per status:</p>
                  <Select
                    onChange={(e) => {
                      this.handleFilterStatus(e.value);
                    }}
                    options={this.state.formData.fields[1].options}
                    placeholder={
                      this.state.statusFilter !== ""
                        ? this.state.statusFilter
                        : "Seleziona"
                    }
                  />
                </div>
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Cerca segnalazione (targa, modello ecc..)"
                    value={this.state.wordFilter}
                    onChange={(e) =>
                      this.handleWordFilter(
                        this.state.wordFilter,
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
              <div className="filter-wrapper">
                <div className="filter-button">
                  <button type="button" onClick={() => this.getList(false)}>
                    <span>Filtra</span>
                  </button>
                  <button
                    className="button-reset"
                    type="button"
                    onClick={() => this.reset()}
                  >
                    <span>RESET</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="filters">
              <div className="filter-wrapper-date">
                <div className="label">Filtra per data:</div>
                <div className="filter-data-start">
                  <DatePicker
                    locale={this.state.language}
                    selected={this.state.startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={this.handleStartDateChange}
                    placeholderText={"Data inizio"}
                  />
                </div>
                {/* <div className="label">"data fine"</div> */}
                <div className="filter-data-end">
                  <DatePicker
                    locale={this.state.language}
                    selected={this.state.endDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={this.handleEndDateChange}
                    placeholderText={"Data fine"}
                  />
                </div>
              </div>
            </div>
            <div className="error-message">{this.state.listMessage}</div>
          </div>
        </div>
      );
    }

    // Status' colors
    const statusColors = {
      Aperto: { color: "green" },
      Sospeso: { color: "orange" },
      Revisionato: { color: "blue" },
      Scaduta: {color:"grey"},
    };

    // List is OK, Render it
    // Prepare data for the table;
    const columns = [
      {
        Header: "Data creazione",
        accessor: "created_on",
        width: 160,
        style: { textAlign: "center" },
        Cell: (c) => (
          <span>{moment(c.original.created_on).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        Header: "Ultima modifica",
        accessor: "last_edited_on",
        width: 170,
        style: { textAlign: "center" },
        Cell: (c) => (
          <span>{moment(c.original.last_edited_on).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        width: 150,
        filterable: true,
        filterMethod: (filter, row) => {
          return row[filter.id]
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        },

        Filter: ({ filter, onChange }) => {
          return (
            <Select
              className={"select-overflow"}
              onChange={(e) => {
                this.handleFilterStatus(e.value);
              }}
              options={this.state.formData.fields[1].options}
              placeholder={
                this.state.statusFilter !== ""
                  ? this.state.statusFilter
                  : "Seleziona"
              }
            />
          );
        },
      },
      {
        Header: "Targa",
        accessor: "plate",
        width: 100,
        style: { textTransform: "uppercase" },
        filterable: true,
        filterMethod: (filter, row) => {
          return row[filter.id]
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        },
        Filter: ({ filter, onChange }) => {
          return (
              <div className="help-tip">
                <p>Le targhe vengono mostrare anonimizzate per il rispetto della privacy.</p>
              </div>
            // <input
            //   onChange={(event) => onChange(event.target.value)}
            //   value={filter ? filter.value : ""}
            //   style={{
            //     width: "100%",
            //     backgroundColor: "transparent",
            //     color: "#222222",
            //     border: "2px solid #2C663C",
            //   }}
            //   placeholder={"Filtra..."}
            // />


          );
        },
      },
      {
        Header: "Modello",
        accessor: "title",
        width: 200,
        style: { overflow: "visible", whiteSpace: "normal" },
        filterable: true,
        filterMethod: (filter, row) => {
          return row[filter.id]
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        },
        Filter: ({ filter, onChange }) => {
          return (
            <input
              onChange={(event) => onChange(event.target.value)}
              value={filter ? filter.value : ""}
              style={{
                width: "100%",
                backgroundColor: "transparent",
                color: "#222222",
                border: "2px solid #2C663C",
              }}
              placeholder={"Filtra..."}
            />
          );
        },
      },
      {
        Header: "Marca",
        accessor: "brand",
        width: 190,
        style: { overflow: "visible", whiteSpace: "normal" },
        filterable: true,
        filterMethod: (filter, row) => {
          return row[filter.id]
              .toLowerCase()
              .includes(filter.value.toLowerCase());
        },
        Filter: ({ filter, onChange }) => {
          return (
              <input
                  onChange={(event) => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    color: "#222222",
                    border: "2px solid #2C663C",
                  }}
                  placeholder={"Filtra..."}
              />
          );
        },
      },
      {
        Header: "Testo",
        accessor: "text",
        // width: 340,
        style: { overflow: "visible", whiteSpace: "normal" },
        filterable: true,
        filterMethod: (filter, row) => {
          return row[filter.id]
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        },
        Filter: ({ filter, onChange }) => {
          return (
            <input
              onChange={(event) => onChange(event.target.value)}
              value={filter ? filter.value : ""}
              style={{
                width: "100%",
                backgroundColor: "transparent",
                color: "#222222",
                border: "2px solid #2C663C",
              }}
              placeholder={"Filtra..."}
            />
          );
        },
        Cell: (c) => (
          <span>
            {(c.original.text.length > 150)
              ? (c.original.text.substring(0, 120)) + " [...] "
              : c.original.text}
          </span>
        ),
      },
      // {
      //   Header: "Autore",
      //   accessor: "author",
      //   width: 250,
      //   style: {
      //     overflow: "visible",
      //     whiteSpace: "normal",
      //     textAlign: "center",
      //   },
      //   filterable: true,
      //   filterMethod: (filter, row) => {
      //     return row[filter.id]
      //       .toLowerCase()
      //       .includes(filter.value.toLowerCase());
      //   },
      //   Filter: ({ filter, onChange }) => {
      //     return (
      //       <input
      //         onChange={(event) => onChange(event.target.value)}
      //         value={filter ? filter.value : ""}
      //         style={{
      //           width: "100%",
      //           backgroundColor: "transparent",
      //           color: "#222222",
      //           border: "2px solid #2C663C",
      //         }}
      //         placeholder={"Filtra..."}
      //       />
      //     );
      //   },
      // },
      {
        Header: "Commenti",
        accessor: "comments",
        width: 175,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "",
        accessor: "commentIcon",
        width: 50,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "",
        accessor: "editIcon",
        width: 50,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "",
        accessor: "deleteIcon",
        width: 50,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
    ];
    const data = [];
    let list = this.state.list;


    for (let c = 0; c < list.length; c++) {
      data.push({
        created_on: list[c].created_on,
        last_edited_on: list[c].last_edited_on,
        author: list[c].author,
        plate: list[c].plate,
        brand: list[c].brand,
        title: list[c].title,
        comments: list[c].comments.length,
        text: list[c].text,
        status: (
          <span>
            <i
              style={{
                color: (list[c].status === "Scaduta" ? statusColors["Scaduta"].color :  statusColors[list[c].status].color),
                fontSize: 16,
                marginRight: 5,
              }}
              className="fa fa-circle"
            ></i>
            {list[c].status === "Scaduta" ? "Scaduta" : list[c].status}
          </span>
        ),
        editIcon:
          this.state.isAdmin === true ||
          parseInt(this.state.userId) === parseInt(list[c].users_id) ? list[c].status !== "Scaduta" ? (
            <i
              className="std-hoverable-cursor-pointer fa fa-edit fa-2x"
              onClick={() => {
                this.editElement(list[c].id);
              }}
            ></i>
          ) : (
            ""
          ):
              ( ""),
        commentIcon: (
          <i
            className="std-hoverable-cursor-pointer fa fa-eye fa-2x"
            onClick={() => {
              this.commentElement(list[c].id);
            }}
          ></i>
        ),
        deleteIcon:
          this.state.isAdmin === true ||
          parseInt(this.state.userId) === parseInt(list[c].users_id) ? (
            <i
              className="std-hoverable-cursor-pointer fa fa-trash fa-2x"
              onClick={() => {
                this.deleteElement(list[c].id);
              }}
            ></i>
          ) : (
            <span></span>
          ),
      });
    }

    if (!this.state.author) {
      return (
        <div className="table-wrapper">
          {/* Logged utente */}
          <div className="logged">
            Accesso effettuato come:
            <div className="author">{this.state.isAdmin ? "Amministratore" : "Utente"} </div>
            <div
              className="page"
              onClick={() => {
                if (window.confirm("Confermi il logout dall'applicazione?")) {
                  this.props.doLogout();
                }
              }}
            >
              <span>
                <i className="fa fa-sign-out"></i>
                Esci
              </span>
            </div>
          </div>
          <div className="filters">
            <div className="filters-wrapper">
              <div className="filter-wrapper">
                <div className="filter">
                  <button type="button" onClick={() => this.newElement()}>
                    <span>
                      {" "}
                      <i className="std-hoverable-cursor-pointer fa fa-plus"></i>{" "}
                      AGGIUNGI{" "}
                    </span>
                  </button>
                </div>
              </div>
              {/* Start Filtri per barra di ricerca in homepage */}
              <div className="filter-wrapper">
                <div className="filter-status">
                  <p className="title-status-filter"> Filtra per status:</p>
                  <Select
                    onChange={(e) => {
                      this.handleFilterStatus(e.value);
                    }}
                    options={this.state.formData.fields[1].options}
                    placeholder={
                      this.state.statusFilter !== ""
                        ? this.state.statusFilter
                        : "Seleziona"
                    }
                  />
                </div>
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Cerca segnalazione (modello, data ecc..)"
                    value={this.state.wordFilter}
                    onChange={(e) =>
                      this.handleWordFilter(
                        this.state.wordFilter,
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
              <div className="filter-wrapper">
                <div className="filter">
                  <div className="filter-button">
                    <button type="button" onClick={() => this.getList(false)}>
                      <span>Filtra</span>
                    </button>
                  </div>
                  <div className="filter-button">
                    <button
                      className="button-reset"
                      type="button"
                      onClick={() => this.reset()}
                    >
                      <span>RESET</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="filters">
              <div className="filter-wrapper-date">
                <div className="label">Filtra per data:</div>
                <div className="filter-data-start">
                  <DatePicker
                    locale={this.state.language}
                    selected={this.state.startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={this.handleStartDateChange}
                    placeholderText={"Data inizio"}
                  />
                </div>
                <div className="filter-data-end">
                  <DatePicker
                    locale={this.state.language}
                    selected={this.state.endDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={this.handleEndDateChange}
                    placeholderText={"Data fine"}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* End Filtri per barra di ricerca in homepage */}
          <ReactTable
            data={data}
            columns={columns}
            pageSizeOptions={[25, 50, 100, 200]}
            defaultPageSize={25}
            previousText={Globals.reactTableText.previous[this.state.language]}
            nextText={Globals.reactTableText.next[this.state.language]}
            loadingText={Globals.reactTableText.loading[this.state.language]}
            noDataText={Globals.reactTableText.noData[this.state.language]}
            pageText={Globals.reactTableText.page[this.state.language]}
            ofText={Globals.reactTableText.of[this.state.language]}
            rowsText={Globals.reactTableText.rows[this.state.language]}
            pageJumpText={
              Globals.reactTableText.pageJumpText[this.state.language]
            }
            rowsSelectorText={
              Globals.reactTableText.rowsSelectorText[this.state.language]
            }
            getTrProps={this.getTrProps}
          />

          {this.state.showForm === true ? (
            <Form
              formData={this.state.formData}
              language={this.state.language}
              accessToken={this.state.accessToken}
              confirmForm={this.confirmForm.bind(this)}
              closeForm={this.closeForm.bind(this)}
              getList={this.getList.bind(this)}
            />
          ) : (
            <div></div>
          )}
          {this.state.showComment === true ? (
            <Comment
              formData={this.state.formData}
              language={this.state.language}
              accessToken={this.state.accessToken}
              closeForm={this.closeForm.bind(this)}
              getList={this.getList.bind(this)}
            />
          ) : (
            <div></div>
          )}
          {this.state.loading === true ? <Loading /> : <div></div>}
        </div>
      );
    } else {
      return (
        <div className="table-wrapper">
          {/* Logged utente */}
          <div className="logged">
            Accesso effettuato come:
            <div className="author">{this.state.author}</div>
            <div
              className="page"
              onClick={() => {
                if (window.confirm("Confermi il logout dall'applicazione?")) {
                  this.props.doLogout();
                }
              }}
            >
              <span>Esci</span>
            </div>
          </div>
          <div className="filters">
            <div className="filters-wrapper">
              <div className="filter-wrapper">
                <div className="filter">
                  <button type="button" onClick={() => this.newElement()}>
                    <span>
                      {" "}
                      <i className="std-hoverable-cursor-pointer fa fa-plus"></i>{" "}
                      AGGIUNGI{" "}
                    </span>
                  </button>
                </div>
              </div>
              {/* Start Filtri per barra di ricerca in homepage */}
              <div className="filter-wrapper">
                <div className="filter-status">
                  <p className="title-status-filter"> Filtra per status:</p>
                  <Select
                    onChange={(e) => {
                      this.handleFilterStatus(e.value);
                    }}
                    options={this.state.formData.fields[1].options}
                    placeholder={
                      this.state.statusFilter !== ""
                        ? this.state.statusFilter
                        : "Seleziona"
                    }
                  />
                </div>
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Cerca segnalazione (targa, modello ecc..)"
                    value={this.state.wordFilter}
                    onChange={(e) =>
                      this.handleWordFilter(
                        this.state.wordFilter,
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
              <div className="filter-wrapper">
                <div className="filter">
                  <div className="filter-button">
                    <button type="button" onClick={() => this.getList(false)}>
                      <span>Filtra</span>
                    </button>
                  </div>
                  <div className="filter-button">
                    <button
                      className="button-reset"
                      type="button"
                      onClick={() => this.reset()}
                    >
                      <span>RESET</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="filters">
              <div className="filter-wrapper-date">
                <div className="label">Filtra per data:</div>
                <div className="filter-data-start">
                  <DatePicker
                    locale={this.state.language}
                    selected={this.state.startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={this.handleStartDateChange}
                    placeholderText={"Data inizio"}
                  />
                </div>
                <div className="filter-data-end">
                  <DatePicker
                    locale={this.state.language}
                    selected={this.state.endDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={this.handleEndDateChange}
                    placeholderText={"Data fine"}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* End Filtri per barra di ricerca in homepage */}
          <ReactTable
            data={data}
            columns={columns}
            pageSizeOptions={[25, 50, 100, 200]}
            defaultPageSize={25}
            previousText={Globals.reactTableText.previous[this.state.language]}
            nextText={Globals.reactTableText.next[this.state.language]}
            loadingText={Globals.reactTableText.loading[this.state.language]}
            noDataText={Globals.reactTableText.noData[this.state.language]}
            pageText={Globals.reactTableText.page[this.state.language]}
            ofText={Globals.reactTableText.of[this.state.language]}
            rowsText={Globals.reactTableText.rows[this.state.language]}
            pageJumpText={
              Globals.reactTableText.pageJumpText[this.state.language]
            }
            rowsSelectorText={
              Globals.reactTableText.rowsSelectorText[this.state.language]
            }
            getTrProps={this.getTrProps}
          />

          {this.state.showForm === true ? (
            <Form
              formData={this.state.formData}
              language={this.state.language}
              accessToken={this.state.accessToken}
              confirmForm={this.confirmForm.bind(this)}
              closeForm={this.closeForm.bind(this)}
              getList={this.getList.bind(this)}
            />
          ) : (
            <div></div>
          )}
          {this.state.showComment === true ? (
            <Comment
              formData={this.state.formData}
              language={this.state.language}
              accessToken={this.state.accessToken}
              closeForm={this.closeForm.bind(this)}
              getList={this.getList.bind(this)}
            />
          ) : (
            <div></div>
          )}
          {this.state.loading === true ? <Loading /> : <div></div>}
        </div>
      );
    }
  }
}
