import React from "react";
import ContainerComments from "../components/ContainerComments.js";
import $ from "jquery";
import Select from "react-select";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import Globals from "../globals/globals";
import { MultiSelect } from "react-multi-select-component";

export default class Board extends React.Component {
  constructor(props) {
    super(props);

    this.displayComments = this.displayComments.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.removeComment = this.removeComment.bind(this);
    this.addNewComment = this.addNewComment.bind(this);
    this.editStatus = this.editStatus.bind(this);

    this.state = {
      comments: [],
      loading: false,
      options: [
        {
          value: "Nessuno",
          label: "Nessuna modifica",
        },
        {
          value: "Sospeso",
          label: "Sospeso",
        },
        {
          value: "Revisionato",
          label: "Revisionato",
        },
      ],

      status: "Nessuno",


      optionsRepair: [
        { label: "Lucidatura fari", value: "1" },
        { label: "Gomme sostiuite", value: "2" },
        { label: "Airbag ripristinato", value: "3" },
        { label: "Fumi regolari", value: "4" },
        { label: "Luci stop riparate", value: "5" },
        { label: "Ammortizzatori sistemati/sostituiti", value: "6" },
        { label: "Servofreno sistemato/sostituito", value: "7" },
        { label: "Bracci anteriori sistemati/sostituiti", value: "8" },
        { label: "Bracci posteriori sistemati/sostituiti", value: "9" },
        { label: "Targhe leggibili", value: "10" },
        { label: "Vetro anteriore riparato/sostituito", value: "11" },
        { label: "Vetro posteriore sistemato/sostituito", value: "12" },
        { label: "Lunotto sistemato/sostituito", value: "13" },
        { label: "Marmitta sistemata/sostituita", value: "14" },
        { label: "Sostituite lampadine", value: "15" },
      ],

      repairsList: [],
    };
  }

  componentDidMount() {
    this.getComment();
  }

  componentWillUnmount(){
    document.body.classList.remove("board");
  }

  getComment() {
    let accessToken = this.props.accessToken;
    let language = "it";

    this.setState({
      loading: true,
    });

    let data = new FormData();
    data.append("id_overhaul", this.props.formData.fields[0].value);

    let url =
      Globals.baseUrl +
      "?lang=" +
      language +
      "&a=getOverhaulComment&access_token=" +
      accessToken;

    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === true) {
          // Error during the call - handle message
          toast(responseJson.message, {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        } else {
          // Call is ok
          let Data = responseJson.comments;
          let arrayComments = [];
          if (Data !== undefined) {
            Data.forEach((element) => {
              arrayComments = arrayComments.concat([element]);
            });
          }

          this.setState({
            loading: false,
            comments: arrayComments,
          });

        }
      })
      .catch((error) => {
        // Communication error - handle message
        toast(Globals.errors.serverCall[this.state.language], {
          autoClose: 8000,
          className: "std-black-toast",
          bodyClassName: "std-black-toast-body",
          progressClassName: "std-black-toast-progress-bar",
        });

        this.setState({
          loading: false,
        });
      });
  }

  editStatus(status) {
    this.setState({
      status: status.value,
    });
  }

  componentDidUpdate(props) {
    if (this.props.formData !== props.formData) {
      this.setState({
        formData: this.props.formData,
      });
      this.getComment();
    }
  }

  handleRepairsList(repairsList) {
    if (repairsList.length === repairsList) {
      this.setState(this.state.repairsList, repairsList);
    } else {
      this.setState({ ...this.state.repairsList, repairsList });
    }
  }

  removeComment(id) {
    let accessToken = this.props.accessToken;
    let language = "it";

    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("id_comment", id);

    let url =
      Globals.baseUrl +
      "?lang=" +
      language +
      "&a=deleteOverhaulComment&access_token=" +
      accessToken;

    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === true) {
          // Error during the call - handle message
          toast(responseJson.message, {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        } else {
          // Call is ok
          toast("Operazione completata", {
            autoClose: 4000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          //this.toggleFilesHandler();
          this.setState({
            loading: false,
          });

          this.props.getList();
        }
      })
      .catch((error) => {
        // Communication error - handle message
        toast(Globals.errors.serverCall[this.state.language], {
          autoClose: 8000,
          className: "std-black-toast",
          bodyClassName: "std-black-toast-body",
          progressClassName: "std-black-toast-progress-bar",
        });

        this.setState({
          loading: false,
        });
      });
  }

  updateComment(status, newText, id, listOfRapairs) {
    let accessToken = this.props.accessToken;
    let language = "it";

    if (newText !== "") {
      const data = new FormData();

      this.setState({
        loading: true,
      });

      data.append("id_comment", id);
      data.append("text", newText);
      data.append("changed_status", status);
      data.append("repair", JSON.stringify(listOfRapairs));

      let url =
        Globals.baseUrl +
        "?lang=" +
        language +
        "&a=editOverhaulComment&access_token=" +
        accessToken;

      fetch(url, {
        method: "POST",
        body: data,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            // Error during the call - handle message
            toast(responseJson.message, {
              autoClose: 8000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });
          } else {
            // Call is ok
            toast("Operazione completata", {
              autoClose: 4000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            //this.toggleFilesHandler();
            this.setState({
              loading: false,
            });

            this.props.getList();

            this.props.loadNewStatus(id);
          }
        })
        .catch((error) => {
          // Communication error - handle message
          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        });
    } else alert("Non è stato scritto alcun commento!");
  }

  addNewComment() {
    let accessToken = this.props.accessToken;
    let user_data = this.props.formData;
    let language = "it";

    var newText = $("#shareCommentText").val();
    if (newText !== "") {
      const data = new FormData();

      this.setState({
        loading: true,
      });

      data.append("id_overhaul", user_data.fields[0].value);
      data.append("text", newText);
      data.append("changed_status", this.state.status);

      if (
        Array.isArray(this.state.repairsList) === true
      ) {
        data.append("repairs", JSON.stringify(this.state.repairsList));
      }
      // else{
      //   data.append("repairs", null);
      // }

      let url =
        Globals.baseUrl +
        "?lang=" +
        language +
        "&a=saveOverhaulComment&access_token=" +
        accessToken;

      fetch(url, {
        method: "POST",
        body: data,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            // Error during the call - handle message
            toast(responseJson.message, {
              autoClose: 8000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });
          } else {
            // Call is ok
            toast("Operazione completata", {
              autoClose: 4000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            //this.toggleFilesHandler();
            this.setState({
              loading: false,
            });

            this.props.getList();
          }
        })
        .catch((error) => {
          // Communication error - handle message
          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        });
    } else alert("Non è stato scritto alcun commento!");
  }

  displayComments(comment, i) {
    return (
      <ContainerComments
        key={i}
        index={i}
        removeCommentFromBoard={this.removeComment}
        updateCommentFromBoard={this.updateComment}
        formData={this.props.formData}
      >
        {comment}
      </ContainerComments>
    );
  }
  render() {
    if (this.state.loading === true) {
      return <Loading />;
    }
    if(this.props.formData.fields[1].value.value === "Scaduta"){
      return (
          <div className="board">
            {this.state.comments.map(this.displayComments)}

            <div className="close-comments">
              {" "}
              <i className="fa fa-lock" /> Pratica scaduta, non più commentabile.
            </div>
          </div>
      );
    }
    if (this.props.formData.fields[1].value.value === "Sospeso") {
      return (
        <div className="board">
          {this.state.comments.map(this.displayComments)}

          <div className="label"> Aggiungi un commento </div>

          <Select
            onChange={(e) => {
              this.editStatus(e);
            }}
            placeholder={"Aggiorna - stato revisione"}
            options={this.state.options}
          />
          <div className="flex-wrap">
          <pre></pre>
          <div className="intestazioneRiparazioni"> Riparazioni effettuate (Non obbligatorio) </div>
            <pre></pre>
            {/* <pre>{JSON.stringify(this.state.repairsList)}</pre> */}
            {/* Case multiselect */}
            <MultiSelect
              overrideStrings={{
                allItemsAreSelected: "Tutti gli elementi selezionati.",
                clearSearch: "Cancella ricerca",
                clearSelected: "Cancella Selezionato",
                noOptions: "Nessuna opzione",
                search: "Cerca",
                selectAll: "Seletiona tutti",
                selectAllFiltered: "Seleziona tutti (Filtrato)",
                selectSomeItems: "Selezionare le riparazioni effetuate...",
                create: "Crea nuovo: ",
              }}
              options={this.state.optionsRepair}
              value={this.state.repairsList}
              hasSelectAll={true}
              onChange={(e) => {
                this.handleRepairsList(e);
              }}
            />
          </div>

          <div className="shareCommentContainer">
            <textarea
              id="shareCommentText"
              placeholder="Scrivi un commento.."
            ></textarea>

            <button onClick={this.addNewComment} className="btn btn-success">
              {" "}
              <i className="fa fa-paper-plane-o" />{" "}
            </button>
          </div>
        </div>
      );
    }
    if (this.props.formData.fields[1].value.value !== "Revisionato" ) {
      return (
        <div className="board">
          {this.state.comments.map(this.displayComments)}

          <div className="label"> Aggiungi un commento </div>

          <Select
            onChange={(e) => {
              this.editStatus(e);
            }}
            placeholder={"Aggiorna - stato revisione"}
            options={this.state.options}
          />

          <div className="flex-wrap">
            <pre></pre>
          <div className="intestazioneRiparazioni"> Riparazioni effettuate (Non obbligatorio) </div>
            <pre></pre>
            {/* <pre>{JSON.stringify(this.state.repairsList)}</pre> */}
            {/* Case multiselect */}
            <MultiSelect
              overrideStrings={{
                allItemsAreSelected: "Tutti gli elementi selezionati.",
                clearSearch: "Cancella ricerca",
                clearSelected: "Cancella Selezionato",
                noOptions: "Nessuna opzione",
                search: "Cerca",
                selectAll: "Seleziona tutti",
                selectAllFiltered: "Seleziona tutti (Filtrato)",
                selectSomeItems: "Seleziona...",
                create: "Crea nuovo: ",
              }}
              options={this.state.optionsRepair}
              value={this.state.repairsList}
              hasSelectAll={true}
              onChange={(e) => {
                this.handleRepairsList(e);
              }}
            />
          </div>

          <div className="shareCommentContainer">
            <textarea
              id="shareCommentText"
              placeholder="Scrivi un commento.."
            ></textarea>

            <button onClick={this.addNewComment} className="btn btn-success">
              {" "}
              <i className="fa fa-paper-plane-o" />{" "}
            </button>
          </div>
        </div>
      );
    }
    // else if(this.props.formData.fields[1].value.value !== "Scaduta"){
    //   return (
    //       <div className="board">
    //         {this.state.comments.map(this.displayComments)}
    //
    //         <div className="close-comments">
    //           {" "}
    //           <i className="fa fa-lock" /> Pratica Scaduta, non più commentabile.
    //         </div>
    //       </div>
    //   );
    //
    // }
    else {
      return (
        <div className="board">
          {this.state.comments.map(this.displayComments)}

          <div className="close-comments">
            {" "}
            <i className="fa fa-lock" /> Pratica chiusa, non più commentabile.
          </div>
        </div>
      );
    }
    if (this.props.formData.fields[1].value.value !== "Revisionato" ) {
      return (
          <div className="board">
            {this.state.comments.map(this.displayComments)}

            <div className="label"> Aggiungi un commento </div>

            <Select
                onChange={(e) => {
                  this.editStatus(e);
                }}
                placeholder={"Aggiorna - stato revisione"}
                options={this.state.options}
            />

            <div className="flex-wrap">
              <pre></pre>
              <div className="intestazioneRiparazioni"> Riparazioni effettuate (Non obbligatorio) </div>
              <pre></pre>
              {/* <pre>{JSON.stringify(this.state.repairsList)}</pre> */}
              {/* Case multiselect */}
              <MultiSelect
                  overrideStrings={{
                    allItemsAreSelected: "Tutti gli elementi selezionati.",
                    clearSearch: "Cancella ricerca",
                    clearSelected: "Cancella Selezionato",
                    noOptions: "Nessuna opzione",
                    search: "Cerca",
                    selectAll: "Seleziona tutti",
                    selectAllFiltered: "Seleziona tutti (Filtrato)",
                    selectSomeItems: "Seleziona...",
                    create: "Crea nuovo: ",
                  }}
                  options={this.state.optionsRepair}
                  value={this.state.repairsList}
                  hasSelectAll={true}
                  onChange={(e) => {
                    this.handleRepairsList(e);
                  }}
              />
            </div>

            <div className="shareCommentContainer">
            <textarea
                id="shareCommentText"
                placeholder="Scrivi un commento.."
            ></textarea>

              <button onClick={this.addNewComment} className="btn btn-success">
                {" "}
                <i className="fa fa-paper-plane-o" />{" "}
              </button>
            </div>
          </div>
      );
    }
    else {
      return (
          <div className="board">
            {this.state.comments.map(this.displayComments)}

            <div className="close-comments">
              {" "}
              <i className="fa fa-lock" /> Pratica chiusa, non più commentabile.
            </div>
          </div>
      );
    }
  }
}
